import React from 'react';
import EtrdBox from '@/ui-kit/EtrdBox';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import { formatDate } from '@/utils/index';
import { MarketplaceAnnouncementsResponseDto } from '@/utils/models/announcement';

interface AnnouncementProps {
  announcement: MarketplaceAnnouncementsResponseDto;
}

export const Announcement: React.FC<AnnouncementProps> = ({ announcement }) => {
  const { title, content, createdAt } = announcement;
  return (
    <EtrdBox p={2}>
      <EtrdTypography fontWeight="bold" variant="subtitle1">
        {title}
      </EtrdTypography>

      <EtrdTypography color="textSecondary" variant="caption">
        {formatDate(createdAt, 'yyyy-MM-dd hh:mm')}
      </EtrdTypography>
      <EtrdTypography mt={2} variant="subtitle1">
        {content}
      </EtrdTypography>
    </EtrdBox>
  );
};
