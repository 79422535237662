import { offersFiltersActionsNames } from '@/store/modules/offerFilters/offersFiltersActions';
import { removeFromArray } from '@/shared/utils/removeFromArray';
import { OfferAttribute } from '@/utils/models/offer';
import { OFFER_TYPE } from '@/utils/models/offer';

export type IOffersFiltersState = {
  filter: any[];
  type: OFFER_TYPE;
  priceMin: number;
  priceMax: number;
  page: number;
  attributes?: OfferAttribute[];
  count?: number;
};

export const offersFiltersInitialState: IOffersFiltersState = {
  filter: [],
  type: OFFER_TYPE.ALL,
  priceMin: 0,
  priceMax: 0,
  page: 1,
  attributes: [],
};

export const offersFiltersReducer = (
  state = offersFiltersInitialState,
  action
): IOffersFiltersState => {
  switch (action.type) {
    case offersFiltersActionsNames.SET_FILTERS:
      return { ...action.filters };
    case offersFiltersActionsNames.ADD_FILTER: {
      return { ...state, ...action.filter };
    }
    case offersFiltersActionsNames.TURN_ON_ATTRIBUTE_FILTER: {
      return { ...state, filter: [...state.filter, action.filter] };
    }
    case offersFiltersActionsNames.TURN_OFF_ATTRIBUTE_FILTER: {
      return {
        ...state,
        filter: removeFromArray(state.filter, action.filter),
      };
    }
    default:
      return state;
  }
};
