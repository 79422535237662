import { Hidden, HiddenProps } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

const StyledHidden = styled(Hidden)`
  ${layout}
`;

interface EtrdHiddenProps extends HiddenProps {
  children?: React.ReactNode;
}

const EtrdHidden = (props: EtrdHiddenProps & LayoutProps) => {
  return (
    <StyledHidden implementation="css" {...props}>
      {props.children}
    </StyledHidden>
  );
};

export default EtrdHidden;
