import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';
import styled from 'styled-components';
import PLACEHOLDER from 'public/assets/banner-placeholder.png';

const BannerWrapper = styled.div`
  width: 100%;
  height: 230px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin: 3rem 0;

  img {
    object-fit: cover;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  @media only screen and (max-width: ${(props) =>
    props.theme.breakpoints.values.lg}px) {
      margin: 2rem 0;
      height: 172px;
}
  @media only screen and (max-width: ${(props) =>
    props.theme.breakpoints.values.md}px) {
      margin: 2rem 0;
      height: 86px;
}
`;

export const MarketplaceBanner = () => {
  const marketplace = useSelector((state: IRootState) => state.marketplace);

  return (
    <BannerWrapper>
      <img alt="banner" src={marketplace.bannerUrl || PLACEHOLDER.src} />
    </BannerWrapper>
  );
};
