import React from 'react';
import EtrdAppBar from '@/layouts/BasicLayout/AppHeader/EtrdAppBar';
import { RightActions } from '@/layouts/BasicLayout/AppHeader/RightActions';
import { useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';
import styled from 'styled-components';
import EtrdImg from '@/ui-kit/EtrdImg';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import { useMediaQuery, useTheme } from '@material-ui/core';

const MarketLogo = styled(EtrdImg)`
  max-width: 300px;
  max-height: 56px;
`;

export const AppHeader = () => {
  const marketplaceData = useSelector((state: IRootState) => state.marketplace);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const Logo = () =>
    marketplaceData.logoUrl ? (
      <MarketLogo
        src={marketplaceData.logoUrl}
        alt={marketplaceData.name}
        title={marketplaceData.name}
        pt={1}
        mr={2}
        mb={mdDown && 2}
      />
    ) : (
      <EtrdTypography>{marketplaceData.name}</EtrdTypography>
    );

  return <EtrdAppBar logo={Logo()} rightActionComponent={<RightActions />} />;
};
