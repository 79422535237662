import React, { useState } from 'react';
import EtrdButton from '@/ui-kit/EtrdButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { EtrdMenu } from '@/ui-kit/EtrdMenu';
import EtrdExpandableList from '@/ui-kit/EtrdList/EtrdExpandableList';
import { useRouter } from 'next/router';
import EtrdLink from '@/ui-kit/EtrdLink';
import ROUTES from '@/shared/constants/routes';

export const CategoryDropdown = ({ category }) => {
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    handleClose();
  }, [router]);

  return (
    <>
      {!category.subcategories?.length ? (
        <EtrdLink
          href={`${ROUTES.CATEGORIES}/${category.slug}`}
          shallow={false}
        >
          <EtrdButton variant={'text'}>{category.name}</EtrdButton>
        </EtrdLink>
      ) : (
        <EtrdButton
          onClick={handleClick}
          variant={'text'}
          data-test={`category-menu-${category.slug}`}
          endIcon={
            !!category.subcategories?.length && <KeyboardArrowDownIcon />
          }
        >
          {category.name}
        </EtrdButton>
      )}

      <EtrdMenu
        data-test={`category-menu-${category.slug}`}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <EtrdExpandableList items={category.subcategories} />
      </EtrdMenu>
    </>
  );
};
