import { notificationActionsNames } from '@/store/modules/notification/notificationActions';
import { Color } from '@material-ui/lab';

export interface INotificationState {
  visible: boolean;
  message: string;
  type: Color;
}

const initialState: INotificationState = {
  visible: false,
  type: 'success',
  message: '',
};

export const notificationsReducer = (
  state = initialState,
  action,
): INotificationState => {
  switch (action.type) {
    case notificationActionsNames.TOGGLE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
