import { useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';
import { getNavBarCategories } from '@/shared/utils/category';
import React from 'react';
import { CategoryDropdown } from '@/layouts/BasicLayout/AppHeader/CategoryDropdown';
import { useTranslation } from 'next-i18next';
import EtrdToolbar from '@/ui-kit/EtrdToolbar';
import EtrdBox from '@/ui-kit/EtrdBox';

export const DesktopCategories = () => {
  const { t } = useTranslation('common');
  const categories = useSelector((state: IRootState) => state.categories);

  if (categories?.length === 0) {
    return null;
  }
  const navCategories = getNavBarCategories(categories);

  const limitedCategories = (categories) =>
    categories?.map((category) => {
      return {
        ...category,
        subcategories: category.subcategories.map((subcategory) => ({
          ...subcategory,
          subcategories: removeSubcategories(subcategory),
        })),
      };
    });

  const removeSubcategories = (subcategory) =>
    subcategory.subcategories.map((subsubcategory) => ({
      ...subsubcategory,
      subcategories: [],
    }));

  const allCategoriesObject = {
    subcategories: limitedCategories(categories),
    name: t('allCategories'),
    slug: 'all-categories',
  };

  return (
    <EtrdToolbar title="">
      <EtrdBox ml={-1}>
        {navCategories?.map((category) => (
          <CategoryDropdown key={category.id} category={category} />
        ))}
        <CategoryDropdown category={allCategoriesObject} />
      </EtrdBox>
    </EtrdToolbar>
  );
};
