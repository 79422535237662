import { TFunction } from 'next-i18next';

export const pluralTransformer = (
  t: TFunction,
  baseKey: string,
  count: number
) => {
  if (count === 1) {
    return t(`${baseKey}_one`);
  }
  return t(`${baseKey}_other`);
};
