import { Color } from '@material-ui/lab';
import React from 'react';
import styled from 'styled-components';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import EtrdBox from '@/ui-kit/EtrdBox';
import CloseIcon from '@material-ui/icons/Close';

interface StyledTopBarProps {
  color: Color;
}

interface Props extends StyledTopBarProps {
  text: string;
  action?: React.ReactElement;
  hasClose?: boolean;
}

const StyledTopBar = styled.div<StyledTopBarProps>`
  background-color: ${(props) => props.theme.palette[props.color].main};
  position: relative;
  padding: 0.5rem;

  p {
    color: ${(props) => props.theme.palette.info.light};
    font-size: 12px;

    @media only screen and (min-width: ${(props) =>
        props.theme.breakpoints.values.md}px) {
      font-size: 16px;
    }
  }

  button {
    font-size: 12px;

    @media only screen and (min-width: ${(props) =>
        props.theme.breakpoints.values.md}px) {
      font-size: 16px;
    }
  }

  .close {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${(props) => props.theme.palette.info.light};
    cursor: pointer;
  }
`;

export const EtrdStickyTopBar: React.FC<Props> = ({
  text,
  color,
  action,
  hasClose,
}) => {
  const [isClosed, close] = React.useState(false);
  const handleClose = () => close(true);
  if (isClosed) {
    return null;
  }
  return (
    <StyledTopBar color={color}>
      <EtrdBox display="flex" justifyContent="center" alignItems="center">
        <EtrdTypography color="textPrimary">{text}</EtrdTypography>
        {action}
      </EtrdBox>

      {hasClose && <CloseIcon className="close" onClick={handleClose} />}
    </StyledTopBar>
  );
};
