import { CreateOfferDto, OfferAttribute } from '../models/offer';

export const CreateOfferPayloadAdapter = (
  offerPayload: any
): CreateOfferDto => {
  const attributeValues = offerPayload.categoryAttributes.map(
    (attribute: OfferAttribute) => offerPayload[attribute.key]
  );
  const reducer = (previousValue: any, currentValue: any) => [
    ...previousValue,
    ...currentValue,
  ];
  const parsedAttributes = attributeValues.length
    ? attributeValues
        .map((a: string | string[]) => (Array.isArray(a) ? a : [a]))
        .reduce(reducer)
    : [];

  const basePayload = {
    primaryCategoryId: Number(offerPayload.primaryCategoryId),
    isEnabled: true,
    price: Number(offerPayload.price) || 0,
    categoryIds: [Number(offerPayload.primaryCategoryId)],
    isPriceNegotiable: offerPayload.isPriceNegotiable || false,
    customAttributes: [],
    quantity: offerPayload.quantity || 1,
  };
  const keys = [
    'title',
    'description',
    'price',
    'isPriceNegotiable',
    'type',
    'isEnabled',
    'quantity',
    'primaryCategoryId',
    'attributeValues',
    'categoryIds',
    'images',
    'customAttributes',
  ];

  const payload = {
    ...offerPayload,
    ...basePayload,
    attributeValues: parsedAttributes
      ?.filter((attribute: any) => {
        return attribute !== undefined;
      })
      .map((id: string) => Number(id)),
  };

  const payloadKeys = Object.keys(payload);
  const purgedPayload: any = {};
  payloadKeys.forEach((key) => {
    if (keys.includes(key)) {
      purgedPayload[key] = payload[key];
    }
  });

  return purgedPayload as CreateOfferDto;
};
