import {
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import styled from 'styled-components';
type Props = DialogProps & { subtitle?: string | React.ReactElement };
const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 100%;
  }
`;

const StyledMuiDialogTitle = styled(MuiDialogTitle)`
  display: flex;
  justify-content: ${(props) => (props.title ? 'space-between' : 'flex-end')};
  align-items: center;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 1.5rem;
  width: 100%;
`;

const Title = ({ onClose, title, subtitle }: Partial<Props>) => {
  return (
    <>
      <StyledMuiDialogTitle disableTypography title={title}>
        {title && <EtrdTypography variant="h6">{title}</EtrdTypography>}
        {onClose ? (
          <IconButton
            size={'small'}
            aria-label="close"
            onClick={() => {
              onClose({}, 'closeButtonClick' as any);
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </StyledMuiDialogTitle>
      {subtitle && (
        <EtrdTypography style={{ padding: '0 1.5rem' }} color="inherit">
          {subtitle}
        </EtrdTypography>
      )}
    </>
  );
};

const EtrdDialog = (props: Props) => {
  return (
    <StyledDialog {...props} title={undefined}>
      <Title {...props} onClose={props.onClose} />
      <StyledDialogContent>{props.children}</StyledDialogContent>
    </StyledDialog>
  );
};

export default EtrdDialog;
