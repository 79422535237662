import AuthRepository from '@/repository/AuthRepository';
import UtilsAppDataRepository from '@/repository/utilsData/UtilsAppDataRepository';
import axios from 'axios';
import OfferRepository from '@/repository/offerRepository/OfferRepository';
import basePath from '@/shared/utils/basePath';
import CategoriesRepository from '@/repository/CategoriesRepository';
import SearchRepository from '@/repository/search/SearchRepository';
import isNode from '@/shared/utils/isNode';
import TraderRepository from '@/repository/TraderRepository';
import MarketplaceRepository from '@/repository/MarketplaceRepository';
import PaymentsRepository from '@/repository/PaymentsRepository';
import Cookies from 'universal-cookie';
import ROUTES, { AUTH_ROOTES } from 'constants/routes';
import { authTokens } from '@/shared/utils/token';
import { NextPageContext } from 'next';
import { endpoints } from '@/repository/endpoints';
import { logError } from '@/shared/utils/logger';
import jwt_decode from 'jwt-decode';

export interface ServiceContainer {
  authRepository: AuthRepository;
  utilsAppDataRepository: UtilsAppDataRepository;
  offerRepository: OfferRepository;
  categoriesRepository: CategoriesRepository;
  searchRepository: SearchRepository;
  traderRepository: TraderRepository;
  marketplaceRepository: MarketplaceRepository;
}

const mountCustomAxios = () => {
  const customAxios = axios.create();
  customAxios.defaults.baseURL = `${basePath}/api/v1`;
  return customAxios;
};

export const ssrApi = (ctx: NextPageContext) => {
  const host = ctx.req.headers.host;
  const cookies = new Cookies(ctx.req.headers.cookie);
  const token = cookies.get(authTokens.Token);
  const refreshToken = cookies.get(authTokens.RefreshToken);

  return apiServiceContainer(token, refreshToken, host);
};

export const clientApi = () => {
  const cookies = new Cookies(); // CLIENT ONLY
  const token = cookies.get(authTokens.Token);
  const refreshToken = cookies.get(authTokens.RefreshToken);
  return apiServiceContainer(token, refreshToken);
};

const tokenWillExpire = (exp: number) => Date.now() + 300000 >= exp * 1000;
const decodedToken = (
  token: string
): {
  exp: number;
} => jwt_decode(token);

export const validateTokenOnSsr = async (ctx: NextPageContext) => {
  const cookies = new Cookies(ctx.req.headers.cookie);
  const token = cookies.get(authTokens.Token);
  const customAxios = mountCustomAxios();
  const host = ctx.req.headers.host;
  const refreshToken = cookies.get(authTokens.RefreshToken);

  if (token && refreshToken) {
    customAxios.interceptors.request.use(function (req) {
      // SET x-forwarded-host TO SET PROPER API URL ON KLUSTER
      req.headers['x-forwarded-host'] = host;
      return req;
    });
    const refreshToken = cookies.get(authTokens.RefreshToken);

    const serverSideRefreshToken = async () => {
      try {
        const newAuthTokens = await customAxios.post(endpoints.auth.refresh, {
          refreshToken,
        });

        ctx.res.setHeader('set-cookie', [
          `${authTokens.Token}=${newAuthTokens.data.data.accessToken};path=${ROUTES.ROOT}`,
          `${authTokens.RefreshToken}=${newAuthTokens.data.data.refreshToken};path=${ROUTES.ROOT}`,
        ]);

        ctx.req.headers[
          'Authorization'
        ] = `Bearer ${newAuthTokens.data.data.accessToken}`;

        ctx.req.headers.cookie = `${authTokens.Token}=${newAuthTokens.data.data.accessToken};path=${ROUTES.ROOT};
        ${authTokens.RefreshToken}=${newAuthTokens.data.data.refreshToken};path=${ROUTES.ROOT}`; // SET NEW COOKIES ALSO FOR CURRENT CTX
      } catch (error) {
        ctx.res.setHeader('set-cookie', [
          `${authTokens.Token}=;max-age=0`,
          `${authTokens.RefreshToken}=;max-age=0`,
        ]);

        ctx.req.headers.cookie = `${authTokens.Token}=};max-age=0;
        ${authTokens.RefreshToken}=;max-age=0`;

        // @ts-ignore
        if (!AUTH_ROOTES.includes(ctx.req.url)) {
          ctx.res.writeHead(302, { Location: ROUTES.LOGIN });
          ctx.res.end();
        }
      }
    };

    if (tokenWillExpire(decodedToken(token).exp)) {
      // if token expires in next 5 min hit refresh
      await serverSideRefreshToken();
    }
  }

  return ctx;
};

const apiServiceContainer = (
  authToken: string,
  refreshToken: string,
  host?: string // OPTIONAL PARAM ONLY FOR SSR
) => {
  const customAxios = mountCustomAxios();
  if (authToken) {
    customAxios.defaults.headers['Authorization'] = `Bearer ${authToken}`;
  }

  customAxios.interceptors.request.use(async (req) => {
    if (isNode && host) {
      // SET x-forwarded-host TO SET PROPER API URL ON KLUSTER
      req.headers['x-forwarded-host'] = host;
    }

    if (
      authToken &&
      tokenWillExpire(decodedToken(authToken).exp) &&
      !isNode &&
      req.url !== endpoints.auth.refresh
    ) {
      // TODO handle client refresh
      // CLIENT ONLY
      const cookies = new Cookies();
      const oldToken = cookies.get(authTokens.Token);

      if (oldToken && refreshToken) {
        const cookies = new Cookies();
        try {
          const newAuthTokens = await customAxios.post(endpoints.auth.refresh, {
            refreshToken,
          });
          cookies.set(authTokens.Token, newAuthTokens.data.data.accessToken, {
            path: ROUTES.ROOT,
            encode: (v) => v,
          });
          cookies.set(
            authTokens.RefreshToken,
            newAuthTokens.data.data.refreshToken,
            {
              path: ROUTES.ROOT,
              encode: (v) => v,
            }
          );
          req.headers[
            'Authorization'
          ] = `Bearer ${newAuthTokens.data.data.accessToken}`;
        } catch (err) {
          cookies.remove(authTokens.Token, { path: ROUTES.ROOT });
          cookies.remove(authTokens.RefreshToken, { path: ROUTES.ROOT });
          customAxios.defaults.headers['Authorization'] = '';
          window.location.href = ROUTES.LOGIN;
        }
      }
    }

    return req;
  });

  customAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        error.response?.status === 401 &&
        !error.config.url.includes(endpoints.auth.refresh)
      ) {
        const originalRequest = error.config;
        if (!isNode) {
          // CLIENT ONLY
          const cookies = new Cookies();
          const oldToken = cookies.get(authTokens.Token);

          if (oldToken && refreshToken) {
            try {
              const newAuthTokens = await customAxios.post(
                endpoints.auth.refresh,
                {
                  refreshToken,
                }
              );
              cookies.set(
                authTokens.Token,
                newAuthTokens.data.data.accessToken,
                {
                  path: ROUTES.ROOT,
                  encode: (v) => v,
                }
              );
              cookies.set(
                authTokens.RefreshToken,
                newAuthTokens.data.data.refreshToken,
                {
                  path: ROUTES.ROOT,
                  encode: (v) => v,
                }
              );
              originalRequest.headers[
                'Authorization'
              ] = `Bearer ${newAuthTokens.data.data.accessToken}`;
              customAxios(originalRequest);
            } catch (err) {
              cookies.remove(authTokens.Token, { path: ROUTES.ROOT });
              cookies.remove(authTokens.RefreshToken, { path: ROUTES.ROOT });
              customAxios.defaults.headers['Authorization'] = '';
              window.location.href = ROUTES.LOGIN;
            }
          }
        }
      }
      if (error.response && error.response.status !== 401 && isNode) {
        // Error handling
        logError(error);
      }
      return Promise.reject(error);
    }
  );

  return {
    authRepository: new AuthRepository(customAxios),
    utilsAppDataRepository: new UtilsAppDataRepository(customAxios),
    offerRepository: new OfferRepository(customAxios),
    categoriesRepository: new CategoriesRepository(customAxios),
    searchRepository: new SearchRepository(customAxios),
    traderRepository: new TraderRepository(customAxios),
    marketplaceRepository: new MarketplaceRepository(customAxios),
    paymentsRepository: new PaymentsRepository(customAxios),
  };
};
