import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import { EtrdCard, EtrdCardActions, EtrdCardContent } from '@/ui-kit/EtrdCard';

import { Price } from '@/components/Price/Price';
import { pluralTransformer } from '@/shared/utils/pluralTransformer';
import EtrdBox from '@/ui-kit/EtrdBox';

const RecommendedFooter = styled(EtrdCardActions)`
  //margin: -1rem;
  padding: 0.5rem;
  background: ${(props) => props.theme.palette.primary.dark};
  color: ${(props) => props.theme.palette.primary.light};
  justify-content: center;
  width: 100%;
`;

const recommendedStyle = (props: any) => {
  return props.recommended
    ? props.theme.palette.primary.dark
    : props.theme.palette.secondary.main;
};

const StyledPlanCard = styled(EtrdCard)<any>`
  width: 320px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: ${(props) =>
    props.selected && `2px solid ${recommendedStyle(props)}`};
  min-height: 154px;

  &:hover {
    background: ${(props) =>
      props.recommended
        ? props.theme.palette.primary.light
        : props.theme.palette.secondary.light};
    cursor: pointer;
  }
  & .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
`;

interface PlanCardProps {
  selected?: boolean;
  plan: any;
  onClick?: () => void;
  isCurrent?: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({
  isCurrent,
  plan,
  selected,
  onClick,
}) => {
  if (!plan) {
    return null;
  }
  const {
    recurringInterval,
    recurringIntervalCount,
    isRecommended,
    unitAmount,
    trial,
  } = plan;
  const { t } = useTranslation(['common', 'paymentPlans', 'units']);

  return (
    <div>
      <StyledPlanCard
        onClick={onClick}
        selected={selected}
        recommended={isRecommended}
      >
        <EtrdCardContent style={{ flex: '1 1 auto' }}>
          <EtrdBox
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <EtrdTypography variant={'h6'} align={'center'} mb={3}>
              {!unitAmount && t('test')} {t('paymentPlans:subscription')}{' '}
              {recurringIntervalCount}{' '}
              {pluralTransformer(
                t,
                `units:${recurringInterval}`,
                recurringIntervalCount
              )}
            </EtrdTypography>
            <EtrdTypography
              variant={'h4'}
              align={'center'}
              color={'secondary'}
              weight={'bold'}
            >
              {unitAmount ? <Price price={Number(unitAmount)} /> : t('free')}
            </EtrdTypography>
          </EtrdBox>
        </EtrdCardContent>
        {isCurrent && (
          <RecommendedFooter>
            <EtrdTypography variant={'button'} weight={'bold'}>
              {t('paymentPlans:isCurrent')}
            </EtrdTypography>
          </RecommendedFooter>
        )}
        {isRecommended && (
          <RecommendedFooter>
            <EtrdTypography variant={'button'} weight={'bold'}>
              {t('recommended')}
            </EtrdTypography>
          </RecommendedFooter>
        )}
      </StyledPlanCard>
    </div>
  );
};

export default PlanCard;
