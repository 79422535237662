import React from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const StyledInput = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
  }
  .Mui-disabled {
    opacity: 0.5;
  }
  ${space}
`;

const EtrdInput = (props: any & SpaceProps) => {
  return <StyledInput {...props} margin={'dense'} variant="outlined" />;
};

export default EtrdInput;
