import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import traderAuthReducer, {
  ITraderAuthModule,
} from '@/store/modules/auth/traderAuth/traderAuthReducer';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import ReduxThunk from 'redux-thunk';
import countriesReducer, {
  ICountriesModule,
} from '@/store/modules/utilsData/counries/countriesReducer';
import messagingAppReducer, {
  IMessagingAppsModule,
} from '@/store/modules/utilsData/messagingApps/messagingAppsReducer';
import preferredContactsReducer, {
  IPreferredContactsModule,
} from './modules/utilsData/preferredContacts/preferredContactsReducer';
import {
  INotificationState,
  notificationsReducer,
} from '@/store/modules/notification/notificationReducer';
import isNode from '@/shared/utils/isNode';
import { categoriesReducer } from '@/store/modules/category/categoryReducer';
import {
  IOffersState,
  offersReducer,
} from '@/store/modules/offers/offersReducer';
import {
  IOffersFiltersState,
  offersFiltersReducer,
} from '@/store/modules/offerFilters/offersFiltersReducer';
import createNewListingReducer, {
  ICreateListingState,
} from '@/store/modules/createNewListing/createNewListingReducer';
import { marketplaceReducer } from '@/store/modules/marketplace/marketplaceReducer';
import { MarketplaceWithLocale } from '@/shared/types/definitions/marketplace';
import { CategoryTreeResponseDto } from '@/utils/models/category';
import { UserSubscriptionDto } from '@/utils/models/payments';
import { userActiveSubscriptionReducer } from '@/store/modules/userActiveSubscription';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}
export interface IRootState {
  traderAuth: ITraderAuthModule;
  countries: ICountriesModule;
  messagingApp: any;
  preferredContacts: IPreferredContactsModule;
  notifications: INotificationState;
  categories: CategoryTreeResponseDto[];
  offers: IOffersState;
  offersFilters: IOffersFiltersState;
  createNewListing: ICreateListingState;
  locale?: string;
  marketplace: MarketplaceWithLocale;
  userActiveSubscription: UserSubscriptionDto;
}

const combinedReducer = combineReducers({
  traderAuth: traderAuthReducer,
  countries: countriesReducer,
  messagingApp: messagingAppReducer,
  preferredContacts: preferredContactsReducer,
  notifications: notificationsReducer,
  categories: categoriesReducer,
  offers: offersReducer,
  offersFilters: offersFiltersReducer,
  createNewListing: createNewListingReducer,
  marketplace: marketplaceReducer,
  userActiveSubscription: userActiveSubscriptionReducer,
  locale: (state) => state || null,
});
// 💩 boilerplate
// 💩 #hateRedux

const reducer = (state: IRootState, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };

    if (state.traderAuth.userProfile)
      nextState.traderAuth.userProfile = state.traderAuth.userProfile;

    if (state.userActiveSubscription)
      nextState.userActiveSubscription = state.userActiveSubscription;

    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

const initStore = () => {
  if (!isNode) {
    // @ts-ignore
    return createStore(
      reducer,
      compose(
        applyMiddleware(ReduxThunk),
        window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__()
          : (f) => f
      )
    );
  }

  return createStore(reducer, applyMiddleware(ReduxThunk));
};

export const reduxWrapper = createWrapper(initStore);
