import { DialogProps } from '@material-ui/core';
import EtrdDialog from '@/ui-kit/EtrdDialog';

interface MarketplaceInfoDialogProps extends DialogProps {
  about: string;
  title: string;
}

export const MarketplaceInfoDialog = ({
  about,
  open,
  onClose,
  title,
}: MarketplaceInfoDialogProps) => {
  return (
    <EtrdDialog open={open} onClose={onClose} title={title} maxWidth={'md'}>
      {about}
    </EtrdDialog>
  );
};
