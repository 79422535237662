import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';
import styled from 'styled-components';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import EtrdBox from '@/ui-kit/EtrdBox';
import EtrdGrid from '@/ui-kit/EtrdGrid';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import PLACEHOLDER from 'public/assets/avatar-placeholder.png';
import { MarketplaceInfoDialog } from '@/components/LandingPage/MarketplaceInfoDialog';
import { EtrdCard, EtrdCardContent } from '@/ui-kit/EtrdCard';
import EtrdImg from '@/ui-kit/EtrdImg';

const MarketplaceInfoWrapper = styled(EtrdCard)`
  border-color: ${(props) => props.theme.palette.primary.main};
  margin-bottom: 3rem;
  background: ${(props) => props.theme.palette.common.white};

  img {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    max-height: 150px;
    max-width: 100%;
  }
`;

const About = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const MarketplaceInfo = () => {
  const marketplace = useSelector((state: IRootState) => state.marketplace);
  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { logoUrl, about, name, slogan } = marketplace;

  return (
    <MarketplaceInfoWrapper>
      <EtrdCardContent>
        <EtrdGrid container alignItems={'center'} spacing={2}>
          <EtrdGrid
            item
            sm={12}
            md={4}
            container
            alignItems={'center'}
            justifyContent={'center'}
          >
            <EtrdImg src={logoUrl || PLACEHOLDER.src} alt="marketplace logo" />
          </EtrdGrid>

          <EtrdGrid item sm={12} md={8} container>
            <EtrdGrid item mb={2} xs={12}>
              <EtrdBox
                display="flex"
                alignItems="center"
                flexWrap={isXSmallScreen ? 'wrap' : 'noWrap'}
              >
                <EtrdBox>
                  <EtrdTypography variant="h6">{name}</EtrdTypography>
                  <EtrdTypography variant={'caption'}>{slogan}</EtrdTypography>
                </EtrdBox>
              </EtrdBox>
            </EtrdGrid>
            <EtrdGrid item xs={12}>
              <EtrdTypography variant="caption" color="textSecondary">
                <About>{about}</About>
                {about.length > 500 && (
                  <>
                    <EtrdBox
                      width="100%"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        variant={'text'}
                        size={'small'}
                        onClick={() => setOpenDialog(true)}
                      >
                        show more
                      </Button>
                    </EtrdBox>
                    <MarketplaceInfoDialog
                      title="About marketplace"
                      about={about}
                      open={openDialog}
                      onClose={() => setOpenDialog(false)}
                    />
                  </>
                )}
              </EtrdTypography>
            </EtrdGrid>
          </EtrdGrid>
        </EtrdGrid>
      </EtrdCardContent>
    </MarketplaceInfoWrapper>
  );
};
