import { Container, CssBaseline, ThemeProvider } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styled, {
  createGlobalStyle,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import Theme from './Theme';

const GlobalStyles = createGlobalStyle`
  body {
  margin: 0;
}

  a {
    text-decoration: none;
  }
`;

const Styled = styled(Container)`
  &.MuiContainer-maxWidthLg {
    max-width: 1200px;
  }
`;

interface EtrdThemeProviderProps {
  children: ReactNode;
  withContainer?: boolean;
}

const EtrdThemeProvider = (props: EtrdThemeProviderProps) => {
  const { withContainer, children } = props;
  let contentRender;
  if (withContainer) {
    contentRender = (
      <Styled>
        <>{children}</>
      </Styled>
    );
  } else {
    contentRender = <>{children}</>;
  }

  return (
    <StyledThemeProvider theme={Theme}>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <CssBaseline />
        {contentRender}
      </ThemeProvider>
    </StyledThemeProvider>
  );
};

export default EtrdThemeProvider;
