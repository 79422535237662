import { clientApi, ssrApi } from '@/repository/serviceContainer';

export const preferredContactsActionsNames = {
  GET_PREFERRED_CONTACTS: 'getPreferredContacts',
};

export const preferredContactsActions = {
  getPreferredContacts: async (dispatch, ctx?) => {
    const client = ctx ? ssrApi : clientApi
    const messagingApp =
      await client(ctx).utilsAppDataRepository.getPreferredContacts();
    dispatch({
      type: preferredContactsActionsNames.GET_PREFERRED_CONTACTS,
      data: messagingApp,
    });
  },
};
