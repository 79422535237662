import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';

export const Metatags = ({marketplace}) => {

  const metaTags = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: marketplace.name },
    { name: 'twitter:description', content: marketplace.slogan },
    { name: 'twitter:image:src', content: marketplace.logoUrl },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'og:title', content: marketplace.name },
    { name: 'og:url', content: marketplace.logoUrl },
    { name: 'og:image', content: marketplace.logoUrl },
    { name: 'og:description', content: marketplace.slogan },
    {
      name: 'og:site_name',
      content: marketplace.name,
    },
    {
      name: 'og:published_time',
      content: new Date().toISOString(),
    },
    {
      name: 'og:modified_time',
      content: new Date().toISOString(),
    },
  ];

  return (
    <>
      <link rel="shortcut icon" href="/favicon.ico" />
      <meta name="description" content={marketplace.slogan} />
      <meta itemProp="name" content={marketplace.name} />
      <meta itemProp="description" content={marketplace.slogan} />
      <meta itemProp="image" content={marketplace.logoUrl} />
      {metaTags.map(({ name, content }) => {
        return <meta key={name} name={name} content={content} />;
      })}
    </>
  );
};
