import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';

const EtrdIconButton = (props: IconButtonProps) => {
  return (
    <IconButton disableFocusRipple disableRipple disableTouchRipple {...props}>
      {props.children}
    </IconButton>
  );
};

export default EtrdIconButton;
