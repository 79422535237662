import {
  IRegistrationFormBasics,
  IRegistrationFormCompanyInfo,
  IRegistrationFormContact,
  IRegistrationFormTraderAuthData,
  IUserMetaData,
} from '@/store/modules/auth/traderAuth/traderAuthReducer';
import Router from 'next/router';
import ROUTES from 'constants/routes';
import { notificationActionsNames } from '@/store/modules/notification/notificationActions';
import { clientApi } from '@/repository/serviceContainer';
import {
  AggregatedTraderResponse,
  CreateTraderDto,
  TraderCompanyResponseDto,
  TraderProfileResponseDto,
} from '@/shared/types/definitions/trader';
import { logError } from '@/shared/utils/logger';

export const traderAuthActionsNames = {
  REGISTER_TRADER_LOADING: 'registerTraderLoading',
  REGISTER_TRADER_SUCCESS: 'registerTraderSuccess',
  REGISTER_TRADER_FAIL: 'registerTraderFail',
  SET_BASICS_FORM_DATA: 'setBasicsFormData',
  SET_COMPANY_INFO_DATA: 'setCompanyInfoData',
  SET_CONTACT_DATA: 'setContactData',
  SET_TRADER_AUTH_DATA: 'setTraderAuthData',
  SET_SELECT_PLAN_STEP: 'setSelectPlanStep',
  SET_PREVIOUS_STEP: 'setPreviousStep',
  SET_USER_METADATA: 'setUserMetadata',
  SELECT_PLAN_LOADING: 'selectPlanLoading',
  SELECT_PLAN_SUCCESS: 'selectPlanSuccess',
  SELECT_PLAN_FAIL: 'selectPlanFail',
  SET_USER_PROFILE: 'setUserProfile',
  UPDATE_USER_PROFILE: 'updateUserProfile',
  UPDATE_USER_COMPANY: 'updateUserCompany',
  LOGOUT: 'logout',
};

export const traderAuthActions = {
  setTraderRegistrationFormBasics: (
    formData: IRegistrationFormBasics,
    dispatch
  ) => {
    dispatch({
      type: traderAuthActionsNames.SET_BASICS_FORM_DATA,
      data: formData,
    });
  },
  setTraderRegistrationFormCompanyInfo: (
    formData: IRegistrationFormCompanyInfo,
    dispatch
  ) => {
    dispatch({
      type: traderAuthActionsNames.SET_COMPANY_INFO_DATA,
      data: formData,
    });
  },
  setTraderRegistrationFormContact: (
    formData: IRegistrationFormContact,
    dispatch
  ) => {
    dispatch({
      type: traderAuthActionsNames.SET_CONTACT_DATA,
      data: { ...formData },
    });
  },
  setTraderRegistrationAuthData: async (
    formData: IRegistrationFormTraderAuthData,
    dispatch,
    combinedRegisterForm: CreateTraderDto,
    successMessage: string
  ) => {
    dispatch({
      type: traderAuthActionsNames.SET_TRADER_AUTH_DATA,
      data: formData,
    });
    return await traderAuthActions.registerTrader(
      dispatch,
      {
        ...combinedRegisterForm,
        ...formData,
      },
      successMessage
    );
  },
  registerTrader: async (
    dispatch,
    combinedRegisterForm: CreateTraderDto,
    successMessage: string
  ) => {
    dispatch({ type: traderAuthActionsNames.REGISTER_TRADER_LOADING });
    const formCopy = { ...combinedRegisterForm };
    delete formCopy['termsAndConditions'];

    try {
      const response = await clientApi().authRepository.registerTrader({
        ...formCopy,
      });
      dispatch({
        type: traderAuthActionsNames.REGISTER_TRADER_SUCCESS,
        message: response.data,
      });
      return Promise.resolve();
    } catch (error) {
      logError(error);
      dispatch({
        type: traderAuthActionsNames.REGISTER_TRADER_FAIL,
        message: error.response.data.errorCode,
      });
      return Promise.reject(error);
    }
  },
  setSelectPlanStep: (dispatch) => {
    dispatch({ type: traderAuthActionsNames.SET_SELECT_PLAN_STEP });
  },
  selectPlan: async (dispatch, planId: string) => {
    dispatch({ type: traderAuthActionsNames.SELECT_PLAN_LOADING });
    try {
      const response = await clientApi().paymentsRepository.selectPrice(planId);
      if (response.data.data.url) {
        window.open(response.data.data.url);
      }
    } catch (error) {
      dispatch({
        type: traderAuthActionsNames.SELECT_PLAN_FAIL,
        message: error,
      });
    }
  },
  setPreviousStep: (dispatch) => {
    dispatch({ type: traderAuthActionsNames.SET_PREVIOUS_STEP });
  },
  setUserMetaData: (data: IUserMetaData, dispatch) =>
    dispatch({ type: traderAuthActionsNames.SET_USER_METADATA, data }),
  setUserProfile: (data: AggregatedTraderResponse, dispatch) =>
    dispatch({ type: traderAuthActionsNames.SET_USER_PROFILE, data }),
  updateUserProfile: (data: TraderProfileResponseDto, dispatch) =>
    dispatch({ type: traderAuthActionsNames.UPDATE_USER_PROFILE, data }),
  updateUserCompany: (data: TraderCompanyResponseDto, dispatch) =>
    dispatch({ type: traderAuthActionsNames.UPDATE_USER_COMPANY, data }),
  logout: (dispatch) => dispatch({ type: traderAuthActionsNames.LOGOUT }),
};
