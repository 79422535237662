import { SearchResponseDto } from '@/shared/types/definitions/search';
import ROUTES from 'constants/routes';

interface SearchItemParsed {
  type: string;
  name?: string;
  title?: string;
  slug?: string;
}

export const createUrlForSearchItem = (searchItem: SearchItemParsed) => {
  if (searchItem.type === 'offers') {
    return `${ROUTES.OFFER}/${searchItem.slug}`;
  }
  if (searchItem.slug) {
    return `/${searchItem.type}/${searchItem.slug}`;
  }

  return `${ROUTES.SEARCH}?q=${searchItem}`;
};

export const parseSearchDTO = (response: SearchResponseDto) => {
  const parsedResults = [];
  const responseProperties = Object.keys(response);

  responseProperties.forEach((property) => {
    response[property].map((searchItem) => {
      parsedResults.push({
        type: property,
        name: searchItem.name || searchItem.title || searchItem,
        href: createUrlForSearchItem(
          searchItem.slug ? { ...searchItem, type: property } : searchItem
        ),
      });
    });
  });
  return parsedResults.filter((r) => r.type !== 'keywords');
};
