import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const StyledImg = styled('img')`
  ${space}
`;

const EtrdImg = (props: any & SpaceProps) => {
  return <StyledImg {...props} />;
};

export default EtrdImg;
