import { InputAdornment, makeStyles, Paper, Popper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import EtrdLink from '@/ui-kit/EtrdLink';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import EtrdAutocomplete from '@/ui-kit/EtrdAutocomplete';
import EtrdInput from '@/ui-kit/EtrdInput';
import { alpha } from '@material-ui/core/styles';
import { Option } from '@/layouts/BasicLayout/AppHeader/Search/Search';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import { useTranslation } from 'next-i18next';

const useStyles = makeStyles((theme) => ({
  inputInput: {
    transition: theme.transitions.create('width', { duration: 100 }),
    width: '300px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
  },
  inputFocused: {
    transition: theme.transitions.create('width', { duration: 100 }),
    width: '382px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
  },
  autocompleteFocused: {
    transition: theme.transitions.create('width', { duration: 100 }),
    width: '582px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const StyledEtrdAutocomplete = styled(EtrdAutocomplete)`
  ${(props) => props.theme.breakpoints.down('xs')} {
    & {
      padding: 0 20px;
    }
  }
`;

const StyledPaper = styled(Paper)`
  &.MuiAutocomplete-paper {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
  &.MuiAutocomplete-paper,
  .MuiAutocomplete-groupLabel {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
  .MuiAutocomplete-groupLabel {
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    text-transform: capitalize;
    line-height: ${(props) => props.theme.typography.h6.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.h6.letterSpacing};
    color: ${(props) => props.theme.palette.common.black};
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  ${(props) => props.theme.breakpoints.down('xs')} {
    .MuiAutocomplete-listbox > li:not(:first-child) {
      margin-top: 20px;
    }
    &&& > ul {
      height: 100%;
      max-height: unset;
    }
  }
`;

const StyledPopper = styled(Popper)`
  ${(props) => props.theme.breakpoints.down('xs')} {
    & {
      height: 100%;
      padding-top: 120px;
      padding-bottom: 40px;
    }
  }
`;

const CustomPaper = (props) => {
  return <StyledPaper {...props} />;
};

const CustomPopper = (props) => {
  return <StyledPopper {...props} />;
};

const StyledEtrdInput = styled(EtrdInput)`
  .MuiOutlinedInput-root {
    background-color: ${(props) =>
      alpha(props.theme.palette.primary.main, 0.15)};
    color: ${(props) => props.theme.palette.primary.main};
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
`;

interface AutocompleteProps {
  isXSmallScreen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setLastHighlight: Dispatch<SetStateAction<Option>>;
  handleSearch: (e: any) => void;
  handlePressEnter: (e: any) => Promise<void>;
  options: Option[];
}

const Autocomplete = ({
  isXSmallScreen,
  setOpen,
  setLastHighlight,
  handleSearch,
  handlePressEnter,
  options,
}: AutocompleteProps) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <StyledEtrdAutocomplete
      fullWidth={isXSmallScreen}
      PaperComponent={CustomPaper}
      PopperComponent={CustomPopper}
      classes={{
        focused: classes.inputFocused,
        paper: classes.autocompleteFocused,
      }}
      onOpen={() => setOpen(true)}
      onHighlightChange={(e, option: Option) => setLastHighlight(option)}
      onClose={() => setOpen(false)}
      noOptionsText={t('noOptions')}
      clearText={t('clear')}
      closeText={t('close')}
      loadingText={t('loading')}
      openText={t('open')}
      renderInput={(params) => (
        <StyledEtrdInput
          {...params}
          autoFocus={isXSmallScreen}
          placeholder={t('search')}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position={'start'}>
                <SearchIcon />
              </InputAdornment>
            ),
            classes: {
              root: classes.inputInput,
              focused: classes.inputFocused,
            },
            ['data-test']: 'search-input',
          }}
          onChange={handleSearch}
          onKeyDown={handlePressEnter}
        />
      )}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <EtrdLink
          style={{ width: '100%', display: 'block' }}
          data-test={`search-record-${option.type}-${option.name}`}
          href={option.href}
        >
          <EtrdTypography weight="light">{option.name}</EtrdTypography>
        </EtrdLink>
      )}
      groupBy={(option) => option.type}
      options={options}
    />
  );
};

export default Autocomplete;
