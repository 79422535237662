import {
  Card,
  CardActions,
  CardActionsProps,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import EtrdDivider from './EtrdDivider';
import { space, SpaceProps, LayoutProps, layout } from 'styled-system';
const StyledCard = styled(Card)`
  &.MuiPaper-rounded {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
  }

  ${space}
  ${layout}
`;

export const EtrdCard = (props: CardProps & SpaceProps & LayoutProps) => {
  return (
    <StyledCard variant="outlined" {...props}>
      {props.children}
    </StyledCard>
  );
};

const StyledEtrdCardHeader = styled(CardHeader)`
  &.MuiCardHeader-root {
    ${(props) => props.theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  }

  .MuiCardHeader-content {
    ${(props) => props.theme.breakpoints.down('xs')} {
      align-self: flex-start;
    }
  }

  .MuiCardHeader-title {
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
  }
  .MuiCardHeader-action {
    align-self: center;
    margin: 0;
    ${(props) => props.theme.breakpoints.down('xs')} {
      align-self: flex-end;
    }
  }
`;

export const EtrdCardHeader = (props: CardHeaderProps) => {
  return (
    <StyledEtrdCardHeader titleTypographyProps={{ variant: 'h6' }} {...props}>
      {props.children}
    </StyledEtrdCardHeader>
  );
};

const StyledCardContent = styled(CardContent)`
  &&& {
    ${space}
  }

  &.MuiCardContent-root:last-child {
    padding-bottom: 1rem;
  }
`;

export const EtrdCardContent = (props: CardContentProps & SpaceProps) => {
  return <StyledCardContent {...props}>{props.children}</StyledCardContent>;
};

interface EtrdCardActionsProps extends CardActionsProps {
  filled?: any;
  justifyContent?: string;
}

const StyledEtrdCardActions = styled(CardActions)<EtrdCardActionsProps>`
  padding: 1rem;
  gap: 1rem;
  justify-content: ${(props) => props.justifyContent || 'stretch'};
  ${(props) =>
    props.filled && `background: ${props.theme.palette.primary.light}`}
`;

export const EtrdCardActions = (props: EtrdCardActionsProps) => {
  return (
    <>
      {props.filled && <EtrdDivider />}
      <StyledEtrdCardActions {...props}>{props.children}</StyledEtrdCardActions>
    </>
  );
};
