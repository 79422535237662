import React from 'react';
import EtrdBox from '@/ui-kit/EtrdBox';
import EtrdGrid from '@/ui-kit/EtrdGrid';
import PlanCard from '@/components/PlanCard/PlanCard';
import { PriceResponseDto } from '@/utils/models/payments';
import { useRouter } from 'next/router';
import ROUTES from 'constants/routes';
import { useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';

export const LandingSubscriptions = ({
  availableTraderPlans,
}: {
  availableTraderPlans: PriceResponseDto[];
}) => {
  const { trialDays } = useSelector((state: IRootState) => state.marketplace);
  const router = useRouter();
  const handleRedirectToRegistration = () => router.push(ROUTES.REGISTRATION);

  const shouldAddTrial = () => trialDays > 0;

  return (
    <EtrdGrid mb={4} container spacing={3} justifyContent="space-evenly">
      {shouldAddTrial() && (
        <EtrdGrid xs={12} md={6} lg={4} item>
          <EtrdBox display="flex" width={1} justifyContent="center" mb={3}>
            <PlanCard
              plan={{
                recurringInterval: 'day',
                recurringIntervalCount: trialDays,
              }}
              selected={false}
              onClick={handleRedirectToRegistration}
            />
          </EtrdBox>
        </EtrdGrid>
      )}
      {availableTraderPlans.map((plan) => (
        <EtrdGrid key={plan.id} xs={12} md={6} lg={4} item>
          <EtrdBox display="flex" width={1} justifyContent="center" mb={3}>
            <PlanCard
              onClick={handleRedirectToRegistration}
              plan={plan}
              selected={false}
              key={plan.id}
            />
          </EtrdBox>
        </EtrdGrid>
      ))}
    </EtrdGrid>
  );
};
