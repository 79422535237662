import { CategoryTreeResponseDto } from '@/utils/models/category';
import { OFFER_TYPE } from '@/utils/models/offer';
import { createNewListingActionsNames } from '@/store/modules/createNewListing/createNewListingActions';

export enum CREATE_LISTING_STEPS {
  TYPE = 0,
  CATEGORY = 1,
  LISTING_DETAILS = 2,
}
export interface CreateListingTypeStep {
  type: OFFER_TYPE;
}
export interface CreateListingCategoryStep {
  primaryCategoryId: string;
}
export interface CreateListingCategoryType {
  category?: CategoryTreeResponseDto;
}

export interface CreateListingListingDetails {
  [key: string]: any;
}

export type ICreateListingState = CreateListingTypeStep &
  CreateListingCategoryType &
  CreateListingCategoryStep &
  CreateListingListingDetails & {
    step: CREATE_LISTING_STEPS;
    categoryAttributes: any[];
  };

const initialState: ICreateListingState = {
  type: null,
  primaryCategoryId: null,
  step: CREATE_LISTING_STEPS.TYPE,
  categoryAttributes: [],
};

const traderAuthReducer = (
  state = initialState,
  action
): ICreateListingState => {
  switch (action.type) {
    case createNewListingActionsNames.SET_OFFER_TYPE:
      return {
        ...state,
        type: action.data.type,
        step: CREATE_LISTING_STEPS.CATEGORY,
      };
    case createNewListingActionsNames.SET_OFFER_CATEGORY:
      return {
        ...state,
        ...action.data,
        step: CREATE_LISTING_STEPS.LISTING_DETAILS,
      };

    case createNewListingActionsNames.SET_CATEGORY_ATTRIBUTES:
      return {
        ...state,
        categoryAttributes: action.data,
      };
    case createNewListingActionsNames.SET_STEP: {
      return {
        ...state,
        step: action.data.step,
      };
    }

    default:
      return state;
  }
};

export default traderAuthReducer;
