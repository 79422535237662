import { OFFER_TYPE } from '@/utils/models/offer';

export const CategoryFilterTransformer = (filters) => {
  const filtersCopy = { ...filters };

  if (filtersCopy.type === OFFER_TYPE.ALL) {
    delete filtersCopy.type;
  }

  if (filtersCopy.filter) {
    filtersCopy.filter = filtersCopy.filter.join();
  }

  if (!filtersCopy.priceMin) {
    delete filtersCopy.priceMin;
  }

  if (!filtersCopy.priceMax) {
    delete filtersCopy.priceMax;
  }
  return filtersCopy;
};
