import { MessagingApp } from '@/utils/models/misc';
import { messagingAppActionsNames } from '@/store/modules/utilsData/messagingApps/messagingAppsActions';

export interface IMessagingAppsModule {
  messagingApp: any;
}

const initialState: IMessagingAppsModule = {
  messagingApp: [],
};

const messagingAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case messagingAppActionsNames.GET_MESSAGING_APP:
      return {
        ...state,
        messagingApp: action.data,
      };
    default:
      return state;
  }
};

export default messagingAppReducer;
