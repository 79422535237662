import React from 'react';
import {
  EtrdList,
  EtrdListItem,
  EtrdListItemIcon,
  EtrdListItemText,
} from '@/ui-kit/EtrdList/EtrdList';
import { ReactElement, useState } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EtrdCollapse from '@/ui-kit/EtrdCollapse';
import EtrdLink from '@/ui-kit/EtrdLink';
import styled from 'styled-components';

export interface ExpandableMenuItem {
  icon?: ReactElement;
  name: string;
  to?: string;
  subcategories?: ExpandableMenuItem[];
  slug?: string;
}

export const ListItemBody = (config: ExpandableMenuItem) => {
  const { icon, name } = config;
  return (
    <>
      {icon && <EtrdListItemIcon>{icon}</EtrdListItemIcon>}
      <EtrdListItemText primary={name} />
    </>
  );
};

export const StyledEtrdListItem = styled(EtrdListItem)`
  margin: 0 1rem;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  &:hover span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
  &.MuiListItem-button:hover {
    width: auto;
  }
`;

export const MenuItem = (config: ExpandableMenuItem) => {
  return (
    <EtrdLink
      data-test={`category-menu-item-${config.slug}`}
      href={`/categories/${config.slug}`}
    >
      <StyledEtrdListItem button>
        <ListItemBody
          data-test={`category-menu-item-${config.slug}`}
          name={config.name}
          icon={config.icon}
        />
      </StyledEtrdListItem>
    </EtrdLink>
  );
};

export const StyledCategoryDropdown = styled.div<{ areSubcategories: boolean }>`
  margin: 0 1rem;

  &:hover {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    background: ${(props) => props.theme.palette.primary.light};
  }

  .MuiFormControlLabel-root {
    margin: 0 1rem;
  }
  .MuiRadio-root {
    margin-left: 0.5rem;
  }

  * {
    width: auto;
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
  }
`;

export const ExpandableMenuItem = (config: ExpandableMenuItem) => {
  const [open, setOpen] = useState(false);
  const areSubcategories = config.subcategories!.length > 0;
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <StyledCategoryDropdown areSubcategories={areSubcategories}>
      <EtrdListItem
        data-test={`category-list-item-${config.slug}`}
        button
        onClick={handleClick}
      >
        <ListItemBody {...config} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </EtrdListItem>
      <EtrdCollapse in={open} timeout="auto" unmountOnExit>
        <EtrdExpandableList items={config.subcategories!} />
      </EtrdCollapse>
    </StyledCategoryDropdown>
  );
};

export const StyledEtrdList = styled(EtrdList)`
  border-radius: ${(props) => props.theme.shape.borderRadius}px;

  &.MuiList-root {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
  }
`;

interface EtrdExpandableListProps {
  items: ExpandableMenuItem[];
}
const EtrdExpandableList = (props: EtrdExpandableListProps) => {
  const { items } = props;
  const createList = (items: ExpandableMenuItem[]) => {
    const menu: Array<ReactElement> = [];
    items.map((menuItem: ExpandableMenuItem, idx: number) => {
      if (
        Array.isArray(menuItem.subcategories) &&
        menuItem.subcategories.length > 0
      ) {
        menu.push(
          <ExpandableMenuItem
            {...menuItem}
            key={idx}
            data-test={`expandable-menu-${menuItem.slug}`}
          />
        );
      } else {
        menu.push(<MenuItem {...menuItem} key={idx} />);
      }
    });
    return menu.concat();
  };

  return <StyledEtrdList disablePadding>{createList(items)}</StyledEtrdList>;
};

export default EtrdExpandableList;
