import { AxiosInstance, AxiosResponse } from 'axios';
import { endpoints } from '@/repository/endpoints';
import { HttpWrappedResponse } from '@/utils/models/misc';
import { ProductResponseDto } from '@/utils/models/payments';

interface StripeUrlResponse {
  url: string;
}

export default class PaymentsRepository {
  constructor(private axiosInstance: AxiosInstance) {}
  getSubscriptions(): Promise<
    AxiosResponse<HttpWrappedResponse<ProductResponseDto[]>>
  > {
    return this.axiosInstance.get(endpoints.payments.products);
  }
  selectPrice(
    priceId: string
  ): Promise<AxiosResponse<HttpWrappedResponse<StripeUrlResponse>>> {
    return this.axiosInstance.post(endpoints.payments.prices, { priceId });
  }

  getMySubscriptions(): Promise<
    AxiosResponse<HttpWrappedResponse<ProductResponseDto[]>>
  > {
    return this.axiosInstance.get(endpoints.payments.prices);
  }

  getDashboardSession(): Promise<
    AxiosResponse<HttpWrappedResponse<StripeUrlResponse>>
  > {
    return this.axiosInstance.post(endpoints.payments.portalSession);
  }
}
