export const TRANSLATION_NAMESPACES = [
  'common',
  'home',
  'registration',
  'login',
  'validations',
  'offers',
  'trader',
  'forgotPassword',
  'fields',
  'userStatus',
  'tradersList',
  'adminCommon',
  'paymentPlans',
  'units',
  'adminCategories',
  'adminAnnouncements',
];
