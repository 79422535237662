import { marketplaceActionsNames } from '@/store/modules/marketplace/marketplaceActions';
import { MarketplaceWithLocale } from '@/shared/types/definitions/marketplace';

const initialState = null;

export const marketplaceReducer = (
  state = initialState,
  action
): MarketplaceWithLocale => {
  switch (action.type) {
    case marketplaceActionsNames.SET_INFO:
      return action.data;
    default:
      return state;
  }
};
