import { AxiosInstance } from 'axios';
import { endpoints } from '@/repository/endpoints';
import { Country, MessagingApp, PreferredContact } from '@/utils/models/misc';
import { SubscriptionResponseDto } from '@/shared/types/definitions/trader';

class UtilsAppDataRepository {
  constructor(private axiosInstance: AxiosInstance) {}

  async getCountries(): Promise<Country[]> {
    const response = await this.axiosInstance.get(endpoints.utils.countries);

    return response.data.data;
  }

  async getMessagingApps(): Promise<MessagingApp> {
    const response = await this.axiosInstance.get(
      endpoints.utils.messagingApps
    );

    return response.data.data;
  }

  async getPreferredContacts(): Promise<PreferredContact> {
    const response = await this.axiosInstance.get(
      endpoints.utils.preferredContacts
    );
    return response.data.data;
  }

  async getSubscriptionsTypes(): Promise<Array<SubscriptionResponseDto>> {
    const response = await this.axiosInstance.get(
      endpoints.utils.subscriptions
    );

    return response.data.data;
  }

  async getTermsAndConditions(): Promise<{ data: { data: { body: string } } }> {
    return await this.axiosInstance.get(endpoints.utils.termsAndConditions);
  }
}

export default UtilsAppDataRepository;
