import React from 'react';
import { Chip, ChipProps } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { OFFER_TYPE } from '@/utils/models/offer';

const sellProperties = css`
  background: ${(props) => props.theme.palette.warning.light};
  color: ${(props) => props.theme.palette.warning.dark};
`;

const buyProperties = css`
  background: ${(props) => props.theme.palette.success.light};
  color: ${(props) => props.theme.palette.success.dark};
`;

const StyledChip = styled(Chip)<EtrdChipProps>`
  &.MuiChip-root {
    line-height: 1;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};

    ${(props) => {
      // @ts-ignore
      return props.type === OFFER_TYPE.SELL
        ? sellProperties
        : // @ts-ignore
        props.type === OFFER_TYPE.BUY
        ? buyProperties
        : '';
    }};
  }
  ${space}
`;

interface EtrdChipProps extends Omit<ChipProps, 'color'>, SpaceProps {
  type?: 'sell' | 'buy' | 'primary' | 'secondary' | 'default';
}

const EtrdChip = (props: EtrdChipProps) => {
  // @ts-ignore
  return <StyledChip {...props} />;
};

export default EtrdChip;
