import { AxiosInstance } from 'axios';
import { endpoints } from '@/repository/endpoints';

export default class MarketplaceRepository {
  constructor(private axiosInstance: AxiosInstance) {}
  async getMarketplace() {
    const response = await this.axiosInstance.get(
      endpoints.marketplace.myMarketplace
    );
    return response.data.data;
  }
  async getMarketplaceAnnouncements() {
    const response = await this.axiosInstance.get(
      endpoints.marketplace.marketplaceAnnouncements
    );
    return response.data.data;
  }
}
