import { NextPageContext } from 'next';
import cookies from 'next-cookies';
import isNode from '@/shared/utils/isNode';
import Cookies from 'universal-cookie';

export enum authTokens {
  Token = 'Token',
  RefreshToken = 'RefreshToken',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const getToken = (ctx: NextPageContext | object = {}) => {
  if (isNode) {
    const { Token } = cookies(ctx);
    return Token;
  } else {
    const cookies = new Cookies(); // CLIENT ONLY
    return cookies.get(authTokens.Token);
  }
};
