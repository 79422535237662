import { AggregatedTraderResponse } from '@/shared/types/definitions/trader';
import { traderAuthActionsNames } from '@/store/modules/auth/traderAuth/traderAuthActions';

export enum REGISTRATION_STEP_ENUM {
  BASICS = 0,
  COMPANY_INFO = 1,
  CONTACT_INFO = 2,
  PASSWORD = 3,
  SELECT_PLAN = 4,
}
export interface IRegistrationFormBasics {
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
  email: string;
}

export interface IRegistrationFormCompanyInfo {
  address: string;
  city: string;
  postalCode: string;
  countryAlpha2?: string;
  taxId?: string;
  yearFounded: number;
  website: string;
}

export interface IRegistrationFormContact {
  mobilePhone: string;
  officePhone: string;
  messagingApp: Array<string>;
  preferredContact: string;
}

export interface IRegistrationFormTraderAuthData {
  username: string;
  password: string;
  passwordConfirmed: string;
}

export interface IUserMetaData {
  isEmailConfirmed: boolean;
  isSubscriptionSet: boolean;
  isVerified: boolean;
  uuid: string;
}

export interface ITraderAuthModule {
  isLogged: boolean;
  isLoading: boolean;
  message: string;
  currentSubscription?: any;
  registrationStep: REGISTRATION_STEP_ENUM;
  registrationFormBasics: IRegistrationFormBasics | never;
  registrationFormCompanyInfo: IRegistrationFormCompanyInfo | never;
  registrationFormContact: IRegistrationFormContact | never;
  registrationFromTraderAuthData: IRegistrationFormTraderAuthData;
  userMeta?: IUserMetaData;
  userProfile?: AggregatedTraderResponse;
  trialPeriodEndsAt?: string;
}

export const authReducerInitialState: ITraderAuthModule = {
  isLogged: false,
  isLoading: false,
  message: '',
  registrationStep: REGISTRATION_STEP_ENUM.BASICS,
  registrationFormBasics: {
    firstName: '',
    lastName: '',
    companyName: '',
    jobTitle: '',
    email: '',
  },
  registrationFormCompanyInfo: {
    address: '',
    city: '',
    postalCode: '',
    taxId: '',
    yearFounded: null,
    website: '',
    countryAlpha2: '',
  },
  registrationFormContact: {
    mobilePhone: '',
    officePhone: '',
    messagingApp: null,
    preferredContact: null,
  },
  registrationFromTraderAuthData: {
    username: '',
    password: '',
    passwordConfirmed: '',
  },
};

const traderAuthReducer = (
  state = authReducerInitialState,
  action
): ITraderAuthModule => {
  switch (action.type) {
    case traderAuthActionsNames.REGISTER_TRADER_SUCCESS:
      return {
        ...state,
        message: action.message,
        isLoading: false,
      };
    case traderAuthActionsNames.REGISTER_TRADER_FAIL:
      return {
        ...state,
        message: action.message,
        isLoading: false,
      };
    case traderAuthActionsNames.REGISTER_TRADER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case traderAuthActionsNames.SET_BASICS_FORM_DATA:
      return {
        ...state,
        registrationFormBasics: action.data,
        registrationStep: REGISTRATION_STEP_ENUM.COMPANY_INFO,
      };
    case traderAuthActionsNames.SET_COMPANY_INFO_DATA:
      return {
        ...state,
        registrationFormCompanyInfo: action.data,
        registrationStep: REGISTRATION_STEP_ENUM.CONTACT_INFO,
      };
    case traderAuthActionsNames.SET_CONTACT_DATA:
      return {
        ...state,
        registrationFormContact: action.data,
        registrationStep: REGISTRATION_STEP_ENUM.PASSWORD,
      };
    case traderAuthActionsNames.SET_TRADER_AUTH_DATA:
      return {
        ...state,
        registrationFromTraderAuthData: action.data,
      };
    case traderAuthActionsNames.SET_SELECT_PLAN_STEP:
      return {
        ...state,
        registrationStep: REGISTRATION_STEP_ENUM.SELECT_PLAN,
      };

    case traderAuthActionsNames.SET_PREVIOUS_STEP:
      return {
        ...state,
        registrationStep: state.registrationStep - 1,
      };
    case traderAuthActionsNames.SET_USER_METADATA:
      return {
        ...state,
        userMeta: action.data,
      };
    case traderAuthActionsNames.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.data,
      };
    case traderAuthActionsNames.UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          profile: action.data,
        },
      };
    case traderAuthActionsNames.UPDATE_USER_COMPANY:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          company: action.data,
        },
      };
    case traderAuthActionsNames.LOGOUT: {
      return {
        ...state,
        userProfile: null,
        userMeta: null,
      };
    }

    default:
      return state;
  }
};

export default traderAuthReducer;
