import React from 'react';
import EtrdBox from '@/ui-kit/EtrdBox';
import styled from 'styled-components';
import EtrdGrid from '@/ui-kit/EtrdGrid';
import EtrdLogo from '@/ui-kit/EtrdLogo/EtrdLogo';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import EtrdLink from '@/ui-kit/EtrdLink';
import { LANDING_URL } from 'constants/footerLinks';
import { useTranslation } from 'next-i18next';

const Wrapper = styled(EtrdBox)`
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.modal};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const PoweredByWrapper = styled(EtrdBox)`
  background-color: ${(props) => props.theme.palette.common.white};
  border-top-right-radius: ${(props) => props.theme.shape.borderRadius}px;
  display: inline-flex;
  padding: 0.5rem 1rem;
  min-width: 200px;
  align-items: center;
  span {
    margin-right: 1rem;
    color: ${(props) => props.theme.palette.primary.default};
  }
  img {
    max-width: 50px;
  }
  position: static !important;
`;

const PoweredBy = () => {
  return (
    <PoweredByWrapper>
      <EtrdTypography variant={'caption'}>powered by</EtrdTypography>

      <EtrdLink href={LANDING_URL} target={'_blank'}>
        <EtrdLogo />
      </EtrdLink>
    </PoweredByWrapper>
  );
};

interface FooterItemProps {
  index: number;
  link: FooterLink;
}

const FooterItem = ({ index, link }: FooterItemProps) => {
  const { t } = useTranslation('common');

  return (
    <EtrdGrid
      key={index}
      item={true}
      md={6}
      container
      justifyContent={'center'}
    >
      <StyledEtrdTypography>
        <EtrdLink data-test={`footer-link-${link.label}`} href={link.href}>
          {link.translate ? t(link.label) : link.label}
        </EtrdLink>
      </StyledEtrdTypography>
    </EtrdGrid>
  );
};

export interface FooterLink {
  label: string;
  href: string;
  translate: boolean;
}

export interface FooterLinks {
  links: FooterLink[];
}

const StyledEtrdTypography = styled(EtrdTypography)`
  color: ${(props) => props.theme.palette.common.white};
`;

export const Footer = ({ links }: FooterLinks) => {
  return (
    <Wrapper>
      <EtrdGrid
        container
        justifyContent={'center'}
        alignContent={'center'}
        mb={3}
      >
        <EtrdGrid item md={6} container pt={3}>
          {links.map((link: FooterLink, index: number) => {
            return (
              <React.Fragment key={index}>
                {index % 2 === 0 ? (
                  <FooterItem key={link.label} link={link} index={index} />
                ) : (
                  <FooterItem key={link.label} link={link} index={index} />
                )}
              </React.Fragment>
            );
          })}
        </EtrdGrid>
      </EtrdGrid>
      <PoweredBy />
    </Wrapper>
  );
};
