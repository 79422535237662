import { useMediaQuery, useTheme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import debounce from 'lodash.debounce';
import { parseSearchDTO } from './helpers';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import ROUTES from 'constants/routes';
import { clientApi } from '@/repository/serviceContainer';
import EtrdDialog from '@/ui-kit/EtrdDialog';
import Autocomplete from '@/layouts/BasicLayout/AppHeader/Search/Autocomplete';
import EtrdHidden from '@/ui-kit/EtrdHidden';
import EtrdIconButton from '@/ui-kit/EtrdIconButton';
import EtrdTooltip from '@/ui-kit/EtrdTooltip';

export interface Option {
  type: string;
  name: string;
  href: string;
}

const Search = () => {
  const router = useRouter();
  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [lastHighlight, setLastHighlight] = useState<Option>();
  const [results, setResults] = useState([]);

  const { t } = useTranslation('common');
  const debouncedFetch = debounce(async (query) => {
    if (query.length >= 2) {
      const response = await clientApi().searchRepository.search(query);
      setResults(parseSearchDTO(response));
    } else return [];
  }, 300);

  const handleSearch = (e) => {
    debouncedFetch(e.target.value);
  };

  const handlePressEnter = async (e) => {
    if (e.code == 'Enter') {
      await router.push(
        lastHighlight
          ? lastHighlight.href
          : `${ROUTES.SEARCH}?q=${e.target.value}`
      );
      e.target.blur();
      setOpen(false);
    }
  };

  return (
    <>
      <EtrdHidden lgUp>
        {isXSmallScreen && (
          <>
            <EtrdTooltip title={t('search')}>
              <EtrdIconButton
                style={{ paddingRight: '0.75rem' }}
                onClick={() => setOpen(true)}
              >
                <SearchIcon color="primary" />
              </EtrdIconButton>
            </EtrdTooltip>
            <EtrdDialog open={open} fullScreen onClose={() => setOpen(false)}>
              <Autocomplete
                key="mobile"
                isXSmallScreen={isXSmallScreen}
                setOpen={setOpen}
                setLastHighlight={setLastHighlight}
                handleSearch={handleSearch}
                handlePressEnter={handlePressEnter}
                options={results}
              />
            </EtrdDialog>
          </>
        )}
      </EtrdHidden>
      <EtrdHidden mdDown>
        {!isXSmallScreen && (
          <Autocomplete
            key="desktop"
            isXSmallScreen={isXSmallScreen}
            setOpen={setOpen}
            setLastHighlight={setLastHighlight}
            handleSearch={handleSearch}
            handlePressEnter={handlePressEnter}
            options={results}
          />
        )}
      </EtrdHidden>
    </>
  );
};

export default Search;
