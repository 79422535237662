import { offersActionsNames } from '@/store/modules/offers/offersActions';
import { OfferWithCategoryAndTraderDto } from '@/utils/models/offer';

export type IOffersState = Array<OfferWithCategoryAndTraderDto>;
const initialState: IOffersState = [];

export const offersReducer = (state = initialState, action): IOffersState => {
  switch (action.type) {
    case offersActionsNames.SET_OFFERS:
      return [...action.offers];
    default:
      return state;
  }
};
