import { AxiosInstance } from 'axios';
import { endpoints } from '@/repository/endpoints';
import { fileUploadHelper } from '@/utils/fileUploadHelper';
import { AggregatedTraderResponse } from '@/shared/types/definitions/trader';

export default class TraderRepository {
  constructor(private axiosInstance: AxiosInstance) {}

  async getTrader(uuid: string): Promise<AggregatedTraderResponse> {
    const response = await this.axiosInstance.get(
      endpoints.trader.getTraderAggregatedData(uuid),
      { params: { profile: true, company: true } }
    );
    return response.data.data;
  }

  async sendMessageToTrader(
    uuid: string,
    message: string,
    sendMessageToMe?: boolean,
    offerSlug?: string
  ): Promise<any> {
    const response = await this.axiosInstance.post(
      endpoints.trader.sendMessageToTrader(uuid),
      {
        body: message,
        sendCopyToSender: sendMessageToMe,
        offerSlug: offerSlug,
      }
    );
    return response.data.data as any;
  }

  async addProfileCompanyImage(image: File): Promise<string> {
    const { formData, config } = fileUploadHelper(image);

    const result = await this.axiosInstance.post(
      endpoints.trader.addProfileCompanyImage,
      formData,
      config
    );

    return result.data.data.logoUrl;
  }

  async updateTraderProfile(traderProfile: any): Promise<any> {
    await this.axiosInstance.put(endpoints.trader.myProfile, traderProfile);
  }

  async updateTraderCompany(traderCompany: any): Promise<any> {
    await this.axiosInstance.put(endpoints.trader.myCompany, traderCompany);
  }

  async updateTraderPassword(password: any): Promise<any> {
    return await this.axiosInstance.patch(
      endpoints.trader.updateTraderPassword,
      password
    );
  }

  async getTraderRecommendations(uuid: string, params: any): Promise<any> {
    return await this.axiosInstance.get(
      endpoints.trader.recommendations(uuid),
      { params: { page: 1 } }
    );
  }

  async sendRecommendation(uuid: string, recommendation: string): Promise<any> {
    return await this.axiosInstance.post(
      endpoints.trader.recommendations(uuid),
      { data: recommendation }
    );
  }
}
