export const fileUploadHelper = (image: File) => {
  const formData = new FormData();
  formData.append('file', image);

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  return {
    formData,
    config,
  };
};
