import { clientApi, ssrApi } from '@/repository/serviceContainer';

export const messagingAppActionsNames = {
  GET_MESSAGING_APP: 'getMessagingApp',
};

export const messagingAppActions = {
  getMessagingApp: async (dispatch, ctx?) => {
    const client = ctx ? ssrApi : clientApi
    const messagingApp =
      await client(ctx).utilsAppDataRepository.getMessagingApps();
    dispatch({
      type: messagingAppActionsNames.GET_MESSAGING_APP,
      data: messagingApp,
    });
  },
};
