import { offerListAdapter } from '@/repository/offerRepository/MarketplaceLandingPageAdapter';
import { CategoryFilterTransformer } from '@/repository/offerRepository/CategoryFilterTransformer';
import { PAGINATION } from 'constants/pagination';
import { AxiosInstance, AxiosResponse } from 'axios';
import { CreateOfferPayloadAdapter } from '@/utils/apiAdapters/CreateOfferPayloadAdapter';
import { fileUploadHelper } from '@/utils/fileUploadHelper';
import { endpoints } from '@/repository/endpoints';

import {
  Offer,
  OfferRequestFilter,
  OfferWithAttributes,
  OFFER_TYPE,
} from '@/utils/models/offer';
import { ResponseWithMeta } from '@/utils/models/misc';

export interface LatestOffer {
  createdAt: string;
  title: string;
  price?: number;
  category: {
    // TODO add categories interface
    id: number;
    name: string;
    slug: string;
  };
  slug: string;
  type?: string;
}

export type RecommendedOfferFilter = {
  page: number;
  limit: number;
  sort: string;
};

export interface LatestOffers {
  sellOffers: LatestOffer[];
  buyOffers: LatestOffer[];
}

class OfferRepository {
  constructor(private axiosInstance: AxiosInstance) {}

  private getOffersUsingFiltersFilters(params: OfferRequestFilter) {
    return this.axiosInstance.get(endpoints.offer.offers, { params });
  }

  async getLatestOffers(): Promise<LatestOffers> {
    const sellOffers = await this.getOffersUsingFiltersFilters({
      page: 1,
      limit: 6,
      type: OFFER_TYPE.SELL,
    });
    const buyOffers = await this.getOffersUsingFiltersFilters({
      page: 1,
      limit: 6,
      type: OFFER_TYPE.BUY,
    });
    // @ts-ignore
    return {
      sellOffers: offerListAdapter(sellOffers.data.data),
      buyOffers: offerListAdapter(buyOffers.data.data),
    };
  }

  async getOffers(filters: OfferRequestFilter) {
    const {
      page = PAGINATION.FIRST_PAGE,
      limit = PAGINATION.DEFAULT_PAGE_SIZE,
      category,
      filter,
      trader,
      priceMin,
      priceMax,
      type,
      keywords,
      sort,
    } = CategoryFilterTransformer(filters);

    const clearParamsFromUndefined = (paramObj) => {
      // TODO create common function
      for (const key in paramObj) {
        if (!paramObj[key]) {
          delete paramObj[key];
        }
      }
      return paramObj;
    };

    const response = await this.getOffersUsingFiltersFilters(
      clearParamsFromUndefined({
        page,
        limit,
        category,
        filter,
        trader,
        priceMin,
        priceMax,
        type,
        keywords,
        sort,
        attributes: true,
      })
    );
    return response.data;
  }

  async getOffer(id: number): Promise<AxiosResponse<OfferWithAttributes>> {
    const request = await this.axiosInstance.get(
      endpoints.offer.singleOffer(id)
    );

    return request.data;
  }
  async getOfferRecommendation(
    id: number,
    filters: RecommendedOfferFilter
  ): Promise<ResponseWithMeta<Offer>> {
    const request = await this.axiosInstance.get(
      endpoints.offer.offerRecommendations(id),
      { params: filters }
    );

    return request.data;
  }
  async toggleOffer(
    offerId: number,
    isEnabled: boolean
  ): Promise<AxiosResponse<any>> {
    return await this.axiosInstance.patch(
      endpoints.offer.mySingleOffer(offerId),
      { isEnabled }
    );
  }
  async addOfferImage(image: File) {
    const { formData, config } = fileUploadHelper(image);

    const result = await this.axiosInstance.post(
      endpoints.offer.uploadOfferImages,
      formData,
      config
    );

    return result.data.data.url;
  }

  async createOffer(offerPayload: any) {
    return this.axiosInstance.post(
      endpoints.offer.myOffers,
      CreateOfferPayloadAdapter(offerPayload)
    );
  }

  async getTraderLatestOffers(uuid: string) {
    const latestTraderOffers = await this.getOffersUsingFiltersFilters({
      page: 1,
      limit: 3,
      trader: uuid,
    });

    return latestTraderOffers.data.data;
  }

  async getTraderOffers(
    filters: any
  ): Promise<AxiosResponse<ResponseWithMeta<Offer>>> {
    return this.axiosInstance.get(`${endpoints.offer.myOffers}`, {
      params: filters,
    });
  }

  async editOffer(
    offer: OfferWithAttributes
  ): Promise<AxiosResponse<OfferWithAttributes>> {
    return this.axiosInstance.put(
      endpoints.offer.mySingleOffer(offer.id),
      CreateOfferPayloadAdapter(offer)
    );
  }
}

export default OfferRepository;
