import { Country } from '@/utils/models/misc';
import { countrirsActionsNames } from '@/store/modules/utilsData/counries/countriesActions';

export interface ICountriesModule {
  countries: Country[];
}

const initialState: ICountriesModule = {
  countries: [],
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case countrirsActionsNames.GET_COUNTRIES:
      return {
        ...state,
        countries: action.data,
      };
    default:
      return state;
  }
};

export default countriesReducer;
