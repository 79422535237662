import { FooterLink } from '@/layouts/BasicLayout/Footer/Footer';
import ROUTES from './routes';

export const FooterLinks: FooterLink[] = [
  {
    label: 'issueRaport',
    translate: true,
    href: ROUTES.FEEDBACK,
  },
  {
    label: 'termsAndConditions',
    translate: true,
    href: ROUTES.TERMS_AND_CONDITIONS,
  },
];

export const LANDING_URL = 'https://8tra.de/';
