import React from 'react';
import {
  Alert,
  AlertProps,
  AlertTitle,
  AlertTitleProps,
} from '@material-ui/lab';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
  }
  &.MuiAlert-outlinedSuccess {
    background-color: ${(props) => props.theme.palette.success.light};
  }
  &.MuiAlert-outlinedError {
    background-color: ${(props) => props.theme.palette.error.light};
  }
  &.MuiAlert-outlinedWarning {
    background-color: ${(props) => props.theme.palette.warning.light};
  }
  &.MuiAlert-outlinedInfo {
    background-color: ${(props) => props.theme.palette.info.light};
  }
  .MuiAlert-message {
    width: 100%;
  }

  ${space}
`;

export const EtrdAlert = (props: AlertProps & SpaceProps) => {
  return (
    <StyledAlert variant={'outlined'} {...props}>
      {props.children}
    </StyledAlert>
  );
};

export const EtrdAlertTitle = (props: AlertTitleProps) => {
  return <AlertTitle {...props}>{props.children}</AlertTitle>;
};

export default EtrdAlert;
