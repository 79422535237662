import getConfig from 'next/config';

interface ClientConfig {
  CLIENT_HOST: string;
  CLUSTER_HOST: string;
  CAPTCHA_SITEKEY: string;
  NX_SHOULD_USE_RECAPTCHA: string;
}

export const config = getConfig().publicRuntimeConfig as ClientConfig;
