import * as React from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link';
import styled from 'styled-components';

type NextComposedProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
> &
  NextLinkProps;

export const Anchor = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const StyledMuiLink = styled(MuiLink)`
  &:hover {
    text-decoration: none;
  }
  color: inherit;
  cursor: pointer;
  display: contents;
`;

const NextComposed = React.forwardRef<HTMLAnchorElement, NextComposedProps>(
  (props, ref) => {
    const { as, href, replace, scroll, passHref, shallow, prefetch, ...other } =
      props;

    return (
      <NextLink
        href={href}
        prefetch={prefetch}
        as={as}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
      >
        <Anchor ref={ref} href={href as any} {...other} />
      </NextLink>
    );
  }
);

interface LinkPropsBase {
  activeClassName?: string;
  innerRef?: React.Ref<HTMLAnchorElement>;
  naked?: boolean;
}

export type LinkProps = LinkPropsBase &
  NextComposedProps &
  Omit<MuiLinkProps, 'href'> & { to?: string };

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
const EtrdLink = (props: LinkProps) => {
  const {
    href,
    activeClassName = 'active',
    className: classNameProps,
    innerRef,
    naked,
    as,
    to,
    ...other
  } = props;

  const router = useRouter();
  const pathname = href
    ? typeof href === 'string'
      ? href
      : href.pathname
    : to;

  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });

  const url = href || to;

  if (naked) {
    return (
      <NextComposed
        className={className}
        ref={innerRef}
        href={url as string}
        {...other}
      />
    );
  }

  return (
    <StyledMuiLink
      component={NextComposed}
      className={className}
      ref={innerRef}
      href={url}
      as={as as any}
      {...other}
    />
  );
};

export default React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <EtrdLink {...props} innerRef={ref} />
));
