import { Snackbar } from '@material-ui/core';
import React from 'react';
import EtrdAlert from '@/ui-kit/EtrdAlert';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';
import { notificationActionsNames } from '@/store/modules/notification/notificationActions';

export const Notification = () => {
  const { visible, message, type } = useSelector(
    (state: IRootState) => state.notifications
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: notificationActionsNames.TOGGLE,
      data: { visible: false },
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      open={visible}
      autoHideDuration={5000}
    >
      <EtrdAlert onClose={handleClose} severity={type}>
        {message}
      </EtrdAlert>
    </Snackbar>
  );
};
