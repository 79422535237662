import { PAGINATION } from 'constants/pagination';
import { offersFiltersInitialState } from '@/store/modules/offerFilters/offersFiltersReducer';

export const offersFiltersActionsNames = {
  SET_FILTERS: 'setFilters',
  ADD_FILTER: 'addFilter',
  TURN_ON_ATTRIBUTE_FILTER: 'turnOnAttributeFilter',
  TURN_OFF_ATTRIBUTE_FILTER: 'turnOffAttributeFilter',
};

export const offersFiltersActions = {
  setFilter: (filters, dispatch) => {
    dispatch({ type: offersFiltersActionsNames.SET_FILTERS, filters });
  },
  addFilter: (filter, dispatch) => {
    dispatch({ type: offersFiltersActionsNames.ADD_FILTER, filter });
  },
  resetFilters: (category, dispatch) => {
    dispatch({
      type: offersFiltersActionsNames.SET_FILTERS,
      filters: { ...offersFiltersInitialState, category },
    });
  },
  handleAddFilter: (filter, dispatch) => {
    const { TURN_OFF_ATTRIBUTE_FILTER, TURN_ON_ATTRIBUTE_FILTER, ADD_FILTER } =
      offersFiltersActionsNames;

    if (filter.filter) {
      Object.keys(filter.filter).map((id) => {
        dispatch({
          type: filter.filter[id]
            ? TURN_ON_ATTRIBUTE_FILTER
            : TURN_OFF_ATTRIBUTE_FILTER,
          filter: id,
        });
      });
      dispatch({
        type: ADD_FILTER,
        filter: { page: PAGINATION.FIRST_PAGE },
      });
    } else {
      const filterToApply = { ...filter };
      if (!filterToApply.page) {
        filterToApply.page = PAGINATION.FIRST_PAGE;
      }
      dispatch({
        type: ADD_FILTER,
        filter: filterToApply,
      });
    }
  },
};
