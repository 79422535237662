import { PAGINATION } from 'constants/pagination';
import { offersFiltersInitialState } from '@/store/modules/offerFilters/offersFiltersReducer';
import { ssrApi } from '@/repository/serviceContainer';

export const getParsedFiltersFromQuery = (query) => {
  const filters = { ...query };
  if (!filters.filter) {
    return { ...filters, filter: [] };
  }
  filters.filter = filters.filter.split(',');
  return filters;
};

export const searchPage = async (dispatch, context) => {
  const query = context.query.q;

  const filtersFromUrl = getParsedFiltersFromQuery(context.query);
  const requestFilters = {
    page: PAGINATION.FIRST_PAGE,
    ...filtersFromUrl,
    keywords: query,
  };
  const offers = await ssrApi(context).offerRepository.getOffers(
    requestFilters,
  );

  const initialPageFilters = {
    ...offersFiltersInitialState,
    ...requestFilters,
    ...offers.meta,
  };

  return {
    offers: offers.data,
    offersFilters: initialPageFilters,
  };
};
