import { createTheme, ThemeOptions } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/zIndex' {
  interface ZIndex {
    mobileDrawer: number;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    gray: Palette['primary'];
    sell: Palette['primary'];
    buy: Palette['primary'];
  }

  interface PaletteOptions {
    gray: PaletteOptions['primary'];
    sell: PaletteOptions['primary'];
    buy: PaletteOptions['primary'];
  }
}

const options: ThemeOptions = {
  zIndex: {
    mobileDrawer: 1299,
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  breakpoints: {
    values: {
      xs: 360,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1600,
    },
  },

  palette: {
    sell: {
      light: '#F5F6F9',
      main: '#5E68A1',
      dark: '#1F2335',
      contrastText: '#fff',
    },
    buy: {
      light: '#F5F6F9',
      main: '#5E68A1',
      dark: '#1F2335',
      contrastText: '#fff',
    },
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#F9F9FB' },

    primary: {
      light: '#F5F6F9',
      main: '#5E68A1',
      dark: '#1F2335',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FDF1F1',
      main: '#EF6F6C',
      dark: '#730F0D',
      contrastText: '#fff',
    },
    error: {
      light: '#FFF2F7',
      main: '#ED2E7E',
      dark: '#C30052',
      contrastText: '#fff',
    },
    warning: {
      light: '#FFF0D4',
      main: '#F4B740',
      dark: '#A26B00',
      contrastText: '#000',
    },
    success: {
      light: '#E8F1E8',
      main: '#4CAF50',
      dark: '#00966D',
      contrastText: '#000000',
    },
    info: {
      light: '#E4EFF9',
      main: '#2196F3',
      dark: '#0962A9',
      contrastText: '#000000',
    },
    gray: {
      light: '#9E9E9E',
      main: '#9E9E9E',
      dark: '#757575',
      contrastText: '#9E9E9E',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    }
  },
  shape: {
    borderRadius: 12,
  },
  props: {
    MuiWithWidth: {
      initialWidth: 'lg',
    },
  },
};

const EtrdTheme = createTheme(options);

export default EtrdTheme;
