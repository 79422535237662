import React, { useContext } from 'react';
import EtrdChip from '@/ui-kit/EtrdChip';
import { OFFER_TYPE } from '@/utils/models/offer';
import { MultiAppContext } from '@/shared-components/MultiAppContext/MultiAppContext';

export const OfferChip = ({ type }: { type: string }) => {
  const { useTranslation } = useContext(MultiAppContext);
  const { t } = useTranslation('offers');

  return (
    <EtrdChip
      size={'small'}
      type={
        type === OFFER_TYPE.SELL
          ? OFFER_TYPE.SELL
          : type === OFFER_TYPE.BUY
          ? OFFER_TYPE.BUY
          : 'default'
      }
      label={t(`${type}Type`).toUpperCase()}
    />
  );
};
