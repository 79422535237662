import React from 'react';
import { Menu, MenuItem, MenuItemProps, MenuProps } from '@material-ui/core';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    border-radius: ${(props) => props.theme.shape.borderRadius};
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

export const EtrdMenu = (props: MenuProps) => {
  return (
    <StyledMenu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...props}
    >
      {props.children}
    </StyledMenu>
  );
};

const StyledMenuItem = styled(MenuItem)`
  .MuiListItem-button:hover {
    border-radius: ${(props) => props.theme.shape.borderRadius};
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;

export const EtrdMenuItem = (props: MenuItemProps) => {
  return (
    // @ts-ignore
    <StyledMenuItem disableRipple disableTouchRipple {...props}>
      {props.children}
    </StyledMenuItem>
  );
};
