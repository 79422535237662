import EtrdButton from '@/ui-kit/EtrdButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { SpaceProps } from 'styled-system';
import ROUTES from 'constants/routes';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const GoHomeButton = (props: SpaceProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  return (
    <EtrdButton
      {...props}
      onClick={() => router.push(ROUTES.ROOT)}
      margin="2rem 0"
      variant={'text'}
      startIcon={<ArrowBackIcon />}
    >
      {t('goBackHome')}
    </EtrdButton>
  );
};

export default GoHomeButton;
