import { PreferredContact } from '@/utils/models/misc';
import { preferredContactsActionsNames } from '@/store/modules/utilsData/preferredContacts/preferredContactsActions';

export interface IPreferredContactsModule {
  preferredContacts: Array<PreferredContact>;
}

const initialState: IPreferredContactsModule = {
  preferredContacts: [],
};

const preferredContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case preferredContactsActionsNames.GET_PREFERRED_CONTACTS:
      return {
        ...state,
        preferredContacts: action.data,
      };
    default:
      return state;
  }
};

export default preferredContactsReducer;
