import React from 'react';

interface MultiAppProps {
  UniversalLink?: any;
  locale: string;
  currencyCode: string;
  marketplaceUrl?: string;
  useTranslation: (namespace?: string | Array<string>) => any;
}

export const MultiAppContext = React.createContext<MultiAppProps>({
  UniversalLink: undefined,
  locale: 'en',
  currencyCode: '',
  useTranslation: (namespaces) => ({ t: (v: any) => v }),
});
