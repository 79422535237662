import React from 'react';
import { AppHeader } from '@/layouts/BasicLayout/AppHeader';
import EtrdBox from '@/ui-kit/EtrdBox';
import { Footer } from '@/layouts/BasicLayout/Footer';
import EtrdGrid from '@/ui-kit/EtrdGrid';
import EtrdContainer from '@/ui-kit/EtrdContainer';
import { FooterLinks } from 'constants/footerLinks';
import GoHomeButton from '@/components/GoHomeButton/GoHomeButton';
import ROUTES from 'constants/routes';
import { useRouter } from 'next/router';

const BASIC_LAYOUT_GO_HOME_EXCLUDED = [
  ROUTES.REGISTRATION,
  ROUTES.LOGIN,
  ROUTES.CHANGE_PASSWORD,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.ROOT,
  ROUTES.ADD_NEW_LISTING,
  ROUTES.SETTINGS_PROFILE,
  ROUTES.SETTINGS_COMPANY,
  ROUTES.SETTINGS_PASSWORD,
  ROUTES.SETTINGS_PAYMENTS,
  ROUTES.FEEDBACK,
];

const CONTAINER_EXCLUDE = [ROUTES.PICK_PLAN, ROUTES.SETTINGS_PAYMENTS];

export default function BasicLayout({ children }) {
  const router = useRouter();

  const shouldRenderContainer = () => {
    let renderContainer = true;
    CONTAINER_EXCLUDE.forEach((url) => {
      if (router.asPath.includes(url)) {
        renderContainer = false;
      }
    });
    return renderContainer;
  };

  return (
    <EtrdGrid
      container
      justifyContent={'space-between'}
      style={{ height: '100vh' }}
    >
      <EtrdGrid item={true} xs={12}>
        <EtrdBox>
          <AppHeader />
          {shouldRenderContainer() ? (
            <EtrdContainer maxWidth="lg">
              {!BASIC_LAYOUT_GO_HOME_EXCLUDED.includes(
                router.asPath as ROUTES
              ) &&
                !router.query?.returnUrl && <GoHomeButton />}
              {children}
            </EtrdContainer>
          ) : (
            children
          )}
        </EtrdBox>
      </EtrdGrid>
      <EtrdGrid item={true} xs={12} mt={6} container alignItems={'flex-end'}>
        <Footer links={FooterLinks} />
      </EtrdGrid>
    </EtrdGrid>
  );
}
