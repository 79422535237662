import React from 'react';
import BasicLayout from '@/layouts/BasicLayout/BasicLayout';
import { LatestOffers } from '@/components/LandingPage/LatestOffers';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { PAGES } from '@/store/modules/initActions/initActions';
import { ssrApi } from '@/repository/serviceContainer';
import { MarketplaceBanner } from '@/components/LandingPage/MarketplaceBanner';
import { LetsSignIn } from '@/components/LandingPage/LetsSignIn';
import { LandingSubscriptions } from '@/components/LandingPage/LandingPageSubscriptions';
import { MarketplaceInfo } from '@/components/LandingPage/MarketplaceInfo';
import { useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';
import Head from 'next/head';
import { AnnouncementsBoard } from '@/components/LandingPage/AnnouncementsBoard';

const Marketplace = ({ offers, availableTraderPlans, announcements }) => {
  const { userProfile } = useSelector((state: IRootState) => state.traderAuth);
  const { name, hasSubscriptions } = useSelector(
    (state: IRootState) => state.marketplace
  );

  return (
    <>
      <Head>
        <title>{name}</title>
      </Head>
      {userProfile && <AnnouncementsBoard announcements={announcements} />}
      <MarketplaceBanner />
      <LatestOffers offers={offers} />
      {!userProfile && (
        <>
          <LetsSignIn />
          {hasSubscriptions && (
            <LandingSubscriptions availableTraderPlans={availableTraderPlans} />
          )}
        </>
      )}

      <MarketplaceInfo />
    </>
  );
};

export async function getServerSideProps(ctx) {
  const { locale } = ctx;
  const serviceContainer = ssrApi(ctx);
  const offers = await serviceContainer.offerRepository.getLatestOffers();

  const availableTraderPlansResponse =
    await serviceContainer.paymentsRepository.getSubscriptions();

  const availableTraderPlans =
    availableTraderPlansResponse.data.data.length !== 0
      ? availableTraderPlansResponse.data.data[0].prices
      : [];

  const announcements =
    await serviceContainer.marketplaceRepository.getMarketplaceAnnouncements();

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'home',
        'common',
        'offers',
        'landingPage',
        'paymentPlans',
        'units',
      ])),
      offers,
      availableTraderPlans,
      announcements,
    },
  };
}

Marketplace.Layout = BasicLayout;
Marketplace.displayName = PAGES.MARKETPLACE;
export default Marketplace;
