import Router from 'next/router';

const UNWANTED_PARAMS = ['attributes', 'category', 'limit', 'count'];

export const applyParamsToUrlAndRedirect = async (params) => {
  Object.keys(params).forEach((key) => {
    if (!UNWANTED_PARAMS.includes(key)) {
      Router.query[key] = params[key];
    }
  });

  if (Array.isArray(Router.query.filter)) {
    Router.query.filter = Router.query.filter.join(',');
  }

  return await Router.push(
    { pathname: Router.pathname, query: Router.query },
    undefined,
    {
      shallow: true,
    },
  );
};
