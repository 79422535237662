import { clientApi } from '@/repository/serviceContainer';
import { isAfter } from 'date-fns';
export const SUB_STATUS = { ACTIVE: 'active' };

export const activeSubscriptionIsPresent = async (
  serviceContainer?: any,
  trialPeriodEndsAt?: string
) => {
  let userActiveSubscription = null;
  let plansResponse;
  if (trialPeriodEndsAt && isAfter(new Date(trialPeriodEndsAt), Date.now())) {
    userActiveSubscription = { trial: true };
  }

  try {
    if (serviceContainer) {
      plansResponse =
        await serviceContainer.paymentsRepository.getMySubscriptions();
    } else {
      plansResponse = await clientApi().paymentsRepository.getMySubscriptions();
    }
  } catch (e) {
    console.log(e);
  }

  plansResponse?.data.data.forEach((subscription) => {
    if (subscription.status === SUB_STATUS.ACTIVE) {
      userActiveSubscription = subscription;
    }
  });
  return userActiveSubscription;
};
