import React from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { IRootState, reduxWrapper } from '@/store/createStore';
import EtrdThemeProvider from '@/ui-kit/EtrdThemeProvider';
import { useEffect } from 'react';
import { Notification } from '@/components/Notification/Notification';
import isNode from '@/shared/utils/isNode';
import { initActions } from '@/store/modules/initActions';
import 'react-country-flag';
import { validateTokenOnSsr } from '@/repository/serviceContainer';
import Head from 'next/head';
import { Metatags } from '@/components/SEO/Metatags';
import { useSelector } from 'react-redux';
import EtrdLink from '@/ui-kit/EtrdLink';
import { MultiAppContext } from '@/shared-components/MultiAppContext/MultiAppContext';
import { TRANSLATION_NAMESPACES } from '@/utils/constants/translations';
import 'react-google-recaptcha';
import { SnackbarProvider } from 'notistack';
import { StylesProvider } from '@material-ui/core/styles';

function EtrdMarketplace(ctx) {
  const { Component, pageProps } = ctx;
  const marketplace = useSelector((state: IRootState) => state.marketplace);
  const { t, i18n } = useTranslation(TRANSLATION_NAMESPACES);
  const Layout = Component.Layout ? Component.Layout : React.Fragment;
  const marketplaceContextInitialValues = {
    t: t,
    UniversalLink: EtrdLink,
    currencyCode: marketplace && marketplace.currencyCode,
    locale: i18n.language,
    useTranslation,
  };
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <StylesProvider injectFirst>
      <MultiAppContext.Provider value={marketplaceContextInitialValues}>
        <EtrdThemeProvider>
          {marketplace ? (
            <Head>
              <Metatags marketplace={marketplace} />
            </Head>
          ) : null}
          <Notification />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </SnackbarProvider>
        </EtrdThemeProvider>
      </MultiAppContext.Provider>
    </StylesProvider>
  );
}

EtrdMarketplace.getInitialProps = reduxWrapper.getInitialAppProps(
  (store) =>
    async ({ Component, ctx, router }) => {
      const { locale } = ctx;
      if (isNode) {
        await validateTokenOnSsr(ctx);
        await initActions.everyPage(
          store.dispatch,
          ctx,
          Component,
          router.locale
        );
      }
      return {
        pageProps: {
          ...(Component.getInitialProps
            ? // @ts-ignore
              await Component.getInitialProps({ ...ctx, store })
            : {}),
        },
      };
    }
);
export default reduxWrapper.withRedux(appWithTranslation(EtrdMarketplace));
