import { removeEmptyProperties } from '@/shared/utils/removeEmptyProperties';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ChangePasswordPayload } from '@/pages/change-password';
import { endpoints } from '@/repository/endpoints';
import {
  AggregatedTraderResponse,
  CreateTraderDto,
  LoginResponseDto,
} from '@/shared/types/definitions/trader';

interface WrappedResponse<T> {
  data: T;
}

class AuthRepository {
  constructor(private axiosInstance: AxiosInstance) {}

  registerTrader(register: CreateTraderDto): Promise<any> {
    return this.axiosInstance.post(
      endpoints.auth.register,
      removeEmptyProperties(register)
    );
  }

  pickSubscription(subscriptionUUID: string): Promise<any> {
    return this.axiosInstance.post(endpoints.auth.subscriptions, {
      subscriptionUUID,
    });
  }

  confirmTraderEmail(uuid: string, token: string): Promise<any> {
    return this.axiosInstance.patch(endpoints.auth.confirmEmail(uuid), {
      token,
    });
  }

  getMetaData(): Promise<{
    uuid: string;
    isVerified: boolean;
    isEmailConfirmed: boolean;
    isSubscriptionSet: boolean;
  }> {
    // @ts-ignore
    return this.axiosInstance
      .get(endpoints.auth.metaData)
      .then((res) => res.data.data);
  }

  async getUserProfile(): Promise<
    AxiosResponse<WrappedResponse<AggregatedTraderResponse>>
  > {
    return this.axiosInstance.get(endpoints.auth.aggregatedData, {
      params: { profile: true, company: true },
    });
  }

  async loginTrader(
    username: string,
    password: string
  ): Promise<LoginResponseDto> {
    const request = await this.axiosInstance.post(endpoints.auth.login, {
      username,
      password,
    });

    return request.data.data;
  }

  async refreshToken(refreshToken: string): Promise<{
    accessToken: string;
    refreshToken: string;
  }> {
    const request = await this.axiosInstance.post(endpoints.auth.refresh, {
      refreshToken,
    });
    // @ts-ignore
    return request.data.data;
  }

  async requestResetPassword(payload: any): Promise<AxiosResponse> {
    return await this.axiosInstance.patch(
      endpoints.auth.resetPassword,
      payload
    );
  }

  async changePassword(
    uuid: string,
    payload: ChangePasswordPayload
  ): Promise<AxiosResponse> {
    return await this.axiosInstance.patch(
      endpoints.auth.changePassword(uuid),
      payload
    );
  }
}

export default AuthRepository;
