import * as React from 'react';
import { EtrdMenu, EtrdMenuItem } from '@/ui-kit/EtrdMenu';
import EtrdLink from '@/ui-kit/EtrdLink';
import ROUTES from 'constants/routes';
import { useState } from 'react';
import useLogin from '@/components/Login/hooks/useLogin';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';
import EtrdIconButton from '@/ui-kit/EtrdIconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export const UserDropdownMenu = () => {
  const profile = useSelector(
    (state: IRootState) => state.traderAuth.userProfile
  );

  const { t } = useTranslation('common');
  const { handleLogout } = useLogin();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutWithClearAnchor = () => {
    handleLogout();
    setAnchorEl(null);
  };

  return (
    <>
      <EtrdMenu
        data-test="user-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <EtrdLink href={`${ROUTES.TRADER}/${profile.profile.traderUUID}`}>
          <EtrdMenuItem data-test="user-menu-my-profile">
            {t('myProfile')}
          </EtrdMenuItem>
        </EtrdLink>
        <EtrdLink href={`${ROUTES.SETTINGS_PROFILE}`}>
          <EtrdMenuItem data-test="user-menu-edit-profile">
            {t('settings')}
          </EtrdMenuItem>
        </EtrdLink>
        <EtrdLink href={`${ROUTES.MATCHED_OFFERS}`}>
          <EtrdMenuItem data-test="user-menu-matched-offers">
            {t('matchedOffers')}
          </EtrdMenuItem>
        </EtrdLink>
        <EtrdLink href={`${ROUTES.MY_OFFERS}`}>
          <EtrdMenuItem data-test="user-menu-my-offers">
            {t('myOffers')}
          </EtrdMenuItem>
        </EtrdLink>
        <EtrdMenuItem
          data-test="user-menu-my-logout"
          onClick={logoutWithClearAnchor}
        >
          {t('logout')}
        </EtrdMenuItem>
      </EtrdMenu>
      <EtrdIconButton
        onClick={handleClick}
        color={'primary'}
        data-test="user-menu-button"
      >
        <AccountCircleIcon />
      </EtrdIconButton>
    </>
  );
};
