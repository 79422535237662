import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import styled from 'styled-components';
import {
  space,
  SpaceProps,
  typography,
  layout,
  TypographyProps as StyledSystemTypographyProps,
} from 'styled-system';

const mapFontNames = (userName: string | undefined): string => {
  switch (userName) {
    case 'light':
      return 'fontWeightLight';
    case 'regular':
      return 'fontWeightRegular';
    case 'medium':
      return 'fontWeightMedium';
    case 'bold':
      return 'fontWeightBold';
    default:
      return 'fontWeightMedium';
  }
};

const StyledTypography = styled(Typography)<EtrdTypographyProps>`
  .MuiTypography-root {
    margin: unset;
  }
  font-weight: ${(props) => props.theme.typography[mapFontNames(props.weight)]};
  ${space}
  ${typography}
  ${layout}
`;

interface EtrdTypographyProps
  extends TypographyProps,
    SpaceProps,
    StyledSystemTypographyProps {
  weight?: 'thin' | 'light' | 'regular' | 'bold' | 'extraBold' | 'black';
}

const EtrdTypography = (props: EtrdTypographyProps) => {
  return <StyledTypography {...props}>{props.children}</StyledTypography>;
};

export default EtrdTypography;
