import React from 'react';
import { Divider, DividerProps } from '@material-ui/core';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const StyledDivider = styled(Divider)`
  ${space}
`;

interface EtrdDividerProps extends DividerProps, SpaceProps {}

const EtrdDivider = (props: EtrdDividerProps) => {
  return <StyledDivider {...props}>{props.children}</StyledDivider>;
};

export default EtrdDivider;
