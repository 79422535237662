import { categoriesActions } from '@/store/modules/category';
import { HYDRATE } from 'next-redux-wrapper';
import { categoryPage, searchPage } from '@/store/modules/initActions';
import { marketplaceActions } from '@/store/modules/marketplace/marketplaceActions';
import { ssrApi } from '@/repository/serviceContainer';
import { authReducerInitialState } from '@/store/modules/auth/traderAuth/traderAuthReducer';
import ROUTES, {
  AUTH_ROOTES,
  PRIVATE_ROUTES_AVAILABLE_FOR_GUEST,
} from '@/shared/constants/routes';
import { logError } from '@/shared/utils/logger';
import { activeSubscriptionIsPresent } from '@/shared/utils/activeSubscriptionIsPresent';
import { settingsPage } from '@/store/modules/initActions/settingsPage';
import { register } from '@/store/modules/initActions/register';

export const PAGES = {
  CATEGORY: 'Category',
  MARKETPLACE: 'Marketplace',
  SEARCH: 'Search',
  ADD_NEW_LISTING: 'AddNewListing',
  OFFER: 'Offer',
  LOGIN: 'Login',
  TRADER: 'Trader',
  FORGOT_PASSWORD: 'ForgotPassword',
  CHANGE_PASSWORD: 'ChangePassword',
  SETTINGS: 'Settings',
  REGISTER: 'Register',
  TERMS_AND_CONDITIONS: 'TermsAndConditions',
  REGISTRATION_SUCCESS: 'RegistrationSuccess',
  CONFIRM_EMAIL: 'ConfirmEmail',
  OFFERS: 'OFFERS',
  EDIT_OFFER: 'EDIT_OFFER',
  FEEDBACK: 'Feedback',
  MATCHED_OFFERS: 'MatchedOffers',
};

export const initActions = {
  everyPage: async (dispatch, context, Component, locale) => {
    if (initActions[Component.displayName]) {
      const marketplaceData = await marketplaceActions.fetchMarketplaceInfo(
        ssrApi(context)
      );
      const marketplace = { ...marketplaceData, locale };
      let userProfile, userActiveSubscription;
      try {
        userProfile = await ssrApi(context).authRepository.getUserProfile();
      } catch (e) {
        logError(e);
      }

      if (
        !marketplaceData.isPublic &&
        !userProfile &&
        !AUTH_ROOTES.includes(context.pathname) &&
        !PRIVATE_ROUTES_AVAILABLE_FOR_GUEST.includes(context.pathname)
      ) {
        await context.res.writeHead(302, { Location: ROUTES.LOGIN });
        await context.res.end();
        return { props: {} };
      }

      if (userProfile && marketplace.hasSubscriptions) {
        try {
          userActiveSubscription = await activeSubscriptionIsPresent(
            ssrApi(context),
            userProfile.data.data.trialPeriodEndsAt
          );
          if (
            !userActiveSubscription &&
            !context.req.url.includes(ROUTES.PICK_PLAN)
          ) {
            await context.res.writeHead(302, { Location: ROUTES.PICK_PLAN });
            await context.res.end();
            return { props: {} };
          }
        } catch (e) {
          logError(e);
        }
      }

      const categories = await categoriesActions.getCategories(ssrApi(context));
      const pageData = await initActions[Component.displayName](
        dispatch,
        context,
        Component,
        locale
      );

      dispatch({
        type: HYDRATE,
        payload: {
          categories,
          marketplace,
          traderAuth: userProfile
            ? {
                userProfile: userProfile.data.data,
                trialPeriodEndsAt: userProfile.data.data.trialPeriodEndsAt,
              }
            : authReducerInitialState,
          ...pageData,
          userActiveSubscription,
          locale,
        },
      });
    }
  },
  [PAGES.CATEGORY]: async (dispatch, context) =>
    categoryPage(dispatch, context),
  [PAGES.MARKETPLACE]: async () => ({}),
  [PAGES.SEARCH]: async (dispatch, context) => searchPage(dispatch, context),
  [PAGES.ADD_NEW_LISTING]: async () => ({}),
  [PAGES.OFFER]: async () => ({}),
  [PAGES.LOGIN]: async () => ({}),
  [PAGES.TRADER]: async () => ({}),
  [PAGES.SETTINGS]: async (dispatch, context) =>
    settingsPage(dispatch, context),
  [PAGES.FORGOT_PASSWORD]: async () => ({}),
  [PAGES.REGISTER]: async (dispatch, context) => register(dispatch, context),
  [PAGES.TERMS_AND_CONDITIONS]: async () => ({}),
  [PAGES.CONFIRM_EMAIL]: async () => ({}),
  [PAGES.OFFERS]: async () => ({}),
  [PAGES.FEEDBACK]: async () => ({}),
  [PAGES.EDIT_OFFER]: async () => ({}),
  [PAGES.MATCHED_OFFERS]: async () => ({}),
};
