import { AppBar, useMediaQuery, useTheme, withWidth } from '@material-ui/core';
import EtrdToolbar from '@/ui-kit/EtrdToolbar';
import styled from 'styled-components';
import EtrdIconButton from '@/ui-kit/EtrdIconButton';
import MenuIcon from '@material-ui/icons/Menu';
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import { EtrdSwappableDrawer } from '@/ui-kit/EtrdDrawer';
import ROUTES from 'constants/routes';
import { DesktopCategories } from '@/layouts/BasicLayout/AppHeader/DesktopCategories';
import { useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';
import EtrdBox from '@/ui-kit/EtrdBox';
import EtrdExpandableList, {
  ExpandableMenuItem,
} from '@/ui-kit/EtrdList/EtrdExpandableList';
import { getNavBarCategories } from '@/shared/utils/category';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import EtrdHidden from '@/ui-kit/EtrdHidden';
import { EtrdStickyTopBar } from '@/ui-kit/EtrdStickyTopBar';
import EtrdButton from '@/ui-kit/EtrdButton';
import { TrendingFlat } from '@material-ui/icons';
import { activeSubscriptionIsPresent } from '@/shared/utils/activeSubscriptionIsPresent';
import { differenceInDays } from 'date-fns';
import { pluralTransformer } from '@/shared/utils/pluralTransformer';
import { Search } from '@/layouts/BasicLayout/AppHeader/Search';
import { EtrdMenuItem } from '@/ui-kit/EtrdMenu';
import EtrdLink from '@/ui-kit/EtrdLink';
import EtrdTypography from '@/../../libs/ui-kit/src/EtrdTypogrphy';
import { MobileNewListingButton } from '@/layouts/BasicLayout/AppHeader/RightActions';
import useLogin from '@/components/Login/hooks/useLogin';

const StyledAppBar = styled(AppBar)`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.values.md}px) {
    z-index: ${(props) => props.theme.zIndex.mobileDrawer};
  }
  background: ${(props) => props.theme.palette.common.white};
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

const Grow = styled.div`
  flex-grow: 1;
`;

const ContentBox = styled.div<{ gap: number }>`
  display: flex;
  gap: ${(props) => props.gap}rem;
  align-items: center;
  justify-content: flex-end;
`;

const Offset = styled.div<{ isTrial: boolean }>`
  ${(props) => props.theme.breakpoints.down('md')} {
    ${(props) => `height: ${props.isTrial ? '187' : '129'}px`}
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    ${(props) => `height: ${props.isTrial ? '106' : '65'}px`}
  }
  ${(props) => props.theme.breakpoints.down('xs')} {
    ${(props) => `height: ${props.isTrial ? '135' : '57'}px`}
  }

  ${(props) => `height: ${props.isTrial ? '181' : '121'}px`}
`;

interface MobileDrawerProps {
  children: ReactNode | ReactElement;
  open: boolean;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

const MobileDrawer = ({ children, open, setOpenDrawer }: MobileDrawerProps) => {
  return (
    <EtrdSwappableDrawer
      anchor="left"
      onClose={() => setOpenDrawer(false)}
      onOpen={() => setOpenDrawer(true)}
      open={open}
    >
      {children}
    </EtrdSwappableDrawer>
  );
};

const MobileMenuItem = styled(EtrdMenuItem)`
  border-radius: 12px;
  margin: 0 1rem;
  padding: 0.7rem 1rem;
`;

interface EtrdAppBar {
  logo?: any;
  leftActionComponent?: ReactElement;
  rightActionComponent?: ReactElement;
}

const EtrdAppBar = (props: EtrdAppBar) => {
  const router = useRouter();
  const { t } = useTranslation(['common', 'paymentPlans']);
  const { logo, leftActionComponent, rightActionComponent } = props;
  const categories = useSelector((state: IRootState) => state.categories);
  const trader = useSelector((state: IRootState) => state.traderAuth);
  const profile = useSelector(
    (state: IRootState) => state.traderAuth.userProfile
  );
  const { handleLogout } = useLogin();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [showTrialBelt, setTrialBelt] = useState<boolean>(false);

  const shouldDisplayTrialBelt = async () => {
    if (!trader.userProfile) {
      setTrialBelt(false);
      return;
    }

    const sub = await activeSubscriptionIsPresent(
      undefined,
      trader.trialPeriodEndsAt
    );

    setTrialBelt(sub && sub.trial);
  };

  React.useEffect(() => {
    setOpenDrawer(false);
    shouldDisplayTrialBelt();
  }, [router]);

  const trialDaysLeft =
    showTrialBelt &&
    differenceInDays(new Date(trader.trialPeriodEndsAt), Date.now()) + 1;

  return (
    <>
      <StyledAppBar position={'fixed'} elevation={0} color={'transparent'}>
        {showTrialBelt && (
          <EtrdStickyTopBar
            color="info"
            text={`${t(
              'freeSubscription'
            )}, ${trialDaysLeft} ${pluralTransformer(
              t,
              'day',
              trialDaysLeft
            )} ${t('left')} `}
            action={
              <EtrdButton
                ml={2}
                style={{ color: theme.palette.info.light }}
                variant="text"
                endIcon={<TrendingFlat />}
                onClick={() => router.push(ROUTES.PICK_PLAN)}
              >
                {t('getSubscription')}
              </EtrdButton>
            }
          />
        )}
        <EtrdToolbar title="">
          {mdDown ? (
            <EtrdBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={1}
            >
              <EtrdIconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpenDrawer(!openDrawer)}
                style={{ marginRight: mdDown ? '65px' : 0 }}
              >
                <MenuIcon color="primary" />
              </EtrdIconButton>
              {logo}
              <EtrdBox display="flex" alignItems="center">
                <MobileNewListingButton />
                <Search />
              </EtrdBox>
            </EtrdBox>
          ) : (
            <>
              <EtrdHidden xsDown>
                <EtrdTypography mr={5} weight={'bold'}>
                  <EtrdLink href={ROUTES.ROOT}>{logo}</EtrdLink>
                </EtrdTypography>
              </EtrdHidden>
              <ContentBox gap={1}>
                <EtrdHidden lgUp>{leftActionComponent}</EtrdHidden>
              </ContentBox>
              <Grow />
              <ContentBox gap={isSmallScreen ? 0 : 3}>
                {rightActionComponent}
              </ContentBox>
            </>
          )}
        </EtrdToolbar>
        <EtrdHidden smDown>
          <DesktopCategories />
        </EtrdHidden>
      </StyledAppBar>
      <EtrdHidden smDown>
        <MobileDrawer open={openDrawer} setOpenDrawer={setOpenDrawer}>
          <EtrdBox mt={3}>
            <EtrdTypography ml={3} mb={2} color="primary" variant="subtitle1">
              {t('categories')}
            </EtrdTypography>
            <EtrdExpandableList items={getNavBarCategories(categories)} />
          </EtrdBox>
          <ExpandableMenuItem
            name={t('allCategories')}
            subcategories={categories}
          />
          <EtrdTypography
            ml={3}
            mb={2}
            mt={3}
            color="primary"
            variant="subtitle1"
          >
            {t('userProfile')}
          </EtrdTypography>
          {profile && (
            <EtrdLink href={ROUTES.MY_OFFERS}>
              <MobileMenuItem>{t('myOffers')}</MobileMenuItem>
            </EtrdLink>
          )}
          {profile && (
            <EtrdLink
              href={`${ROUTES.TRADER}/${trader.userProfile?.profile.traderUUID}`}
            >
              <MobileMenuItem>{t('myProfile')}</MobileMenuItem>
            </EtrdLink>
          )}
          {profile && (
            <EtrdLink href={ROUTES.SETTINGS_PROFILE}>
              <MobileMenuItem>{t('settings')}</MobileMenuItem>
            </EtrdLink>
          )}
          {profile && (
            <EtrdLink href={ROUTES.MATCHED_OFFERS}>
              <MobileMenuItem>{t('matchedOffers')}</MobileMenuItem>
            </EtrdLink>
          )}
          {profile && (
            <EtrdLink href={'#'}>
              <MobileMenuItem onClick={handleLogout}>
                {t('logout')}
              </MobileMenuItem>
            </EtrdLink>
          )}
          {!profile && (
            <EtrdLink href={ROUTES.REGISTRATION}>
              <MobileMenuItem>{t('register')}</MobileMenuItem>
            </EtrdLink>
          )}
          {!profile && (
            <EtrdLink href={ROUTES.LOGIN}>
              <MobileMenuItem>{t('login')}</MobileMenuItem>
            </EtrdLink>
          )}
        </MobileDrawer>
      </EtrdHidden>

      <Offset isTrial={showTrialBelt} />
    </>
  );
};

export default withWidth()(EtrdAppBar);
