import {
  CREATE_LISTING_STEPS,
  CreateListingCategoryStep,
  CreateListingTypeStep,
} from '@/store/modules/createNewListing/createNewListingReducer';
import { clientApi } from '@/repository/serviceContainer';
import { notificationActionsNames } from '@/store/modules/notification/notificationActions';
import Router from 'next/router';
import ROUTES from 'constants/routes';
import { logError } from '@/shared/utils/logger';

export const createNewListingActionsNames = {
  SET_OFFER_TYPE: 'setOfferType',
  SET_OFFER_CATEGORY: 'setOfferCategory',
  SET_CATEGORY_ATTRIBUTES: 'setCategoryAttributes',
  SET_STEP: 'setStep',
};

export const createNewListingActions = {
  setStep: (step: CREATE_LISTING_STEPS, dispatch) => {
    dispatch({
      type: createNewListingActionsNames.SET_STEP,
      data: {
        step,
      },
    });
  },
  setListingType: (formData: CreateListingTypeStep, dispatch) => {
    dispatch({
      type: createNewListingActionsNames.SET_OFFER_TYPE,
      data: formData,
    });
  },
  setListingCategory: async (formData: CreateListingCategoryStep, dispatch) => {
    //TODO: Purge any when HTTP CIENT WILL BE FIXED
    const attributesResponse: any =
      await clientApi().categoriesRepository.getCategory(
        +formData.primaryCategoryId
      );
    dispatch({
      type: createNewListingActionsNames.SET_CATEGORY_ATTRIBUTES,
      data: attributesResponse.data.data.attributes,
    });

    dispatch({
      type: createNewListingActionsNames.SET_OFFER_CATEGORY,
      data: { ...formData, ...attributesResponse.data.data },
    });
  },

  createOffer: async (payload: any, dispatch, t) => {
    try {
      const newOffer = await clientApi().offerRepository.createOffer(payload);
      dispatch({
        type: notificationActionsNames.TOGGLE,
        data: {
          type: 'success',
          message: t('offerCreateSuccessMessage'),
          visible: true,
        },
      });
      await Router.push(`${ROUTES.OFFER}/${newOffer.data.data.slug}`);
    } catch (e) {
      logError(e);
      dispatch({
        type: notificationActionsNames.TOGGLE,
        data: {
          type: 'error',
          message: t('offerCreateFailMessage'),
          visible: true,
        },
      });
    }
  },
};
