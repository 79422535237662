import React from 'react';
import { IRootState } from '@/store/createStore';
import { useSelector } from 'react-redux';

interface Props {
  price: number;
  currencyCode?: string;
}

export const Price: React.FC<Props> = ({ price, currencyCode }) => {
  const marketplace = useSelector((state: IRootState) => state.marketplace);
  const getPrice = (price) =>
    new Intl.NumberFormat(marketplace.locale, {
      style: 'currency',
      currency: currencyCode || marketplace.currencyCode,
    }).format(price);
  return <>{getPrice(price)}</>;
};
