import { ssrApi } from '@/repository/serviceContainer';
export const settingsPage = async (dispatch, context) => {
  const countries = await ssrApi(context).utilsAppDataRepository.getCountries();
  const messagingApp = await ssrApi(
    context
  ).utilsAppDataRepository.getMessagingApps();
  const preferredContacts = await ssrApi(
    context
  ).utilsAppDataRepository.getPreferredContacts();

  return {
    countries: { countries },
    messagingApp: { messagingApp },
    preferredContacts: { preferredContacts },
  };
};
