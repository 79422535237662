import React from 'react';
import { Grid, GridProps } from '@material-ui/core';
import styled from 'styled-components';
import { SpaceProps, space } from 'styled-system';

type EtrdGrid = GridProps & SpaceProps & { order?: number };

const StyledGrid = styled(Grid as any)`
  ${(props) => props.order && `order: ${props.order};`}
  ${space}
`;

const EtrdGrid = (props: EtrdGrid) => {
  return <StyledGrid {...props}>{props.children}</StyledGrid>;
};

export default EtrdGrid;
