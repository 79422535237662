import styled, { css } from 'styled-components';
import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';
import { space, SpaceProps } from 'styled-system';

interface EtrdButtonProps extends ButtonProps {
  success?: boolean;
  error?: boolean;
}

const successProperties = css<EtrdButtonProps>`
  background: ${(props) => props.theme.palette.black['500']};
  border-color: ${(props) => props.theme.black['500']};
  color: ${(props) => props.theme.primary['500']};
  :hover {
    border-color: ${(props) => props.theme.primary['800']};
    background: ${(props) => props.theme.primary['800']};
    border-width: 2px;
    color: ${(props) => props.theme.primary['100']};
  }
  ,
  :disabled {
    border-color: ${(props) => props.theme.primary['700']};
    background: ${(props) => props.theme.primary['700']};
    border-width: 2px;
    color: ${(props) => props.theme.primary['400']};
    opacity: 1;
  }
`;

const StyledMaterialButton = styled(Button)<EtrdButtonProps>`
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  ${(props) => (props.success ? successProperties : '')};
  &.MuiButton-outlined {
    border-width: 2px;
  }
  &.MuiButton-outlinedSecondary {
    border-color: ${(props) => props.theme.palette.secondary.main};
  }
  &.MuiButton-outlinedPrimary {
    border-color: ${(props) => props.theme.palette.primary.main};

    &:disabled {
      border-color: ${(props) => props.theme.palette.info.light};
      color: ${(props) => props.theme.palette.info.light};
    }
  }

  ${space}
`;

const EtrdButton = (props: ButtonProps & SpaceProps) => {
  const { variant = 'contained' } = props;
  return (
    <StyledMaterialButton
      disableElevation
      color={'primary'}
      variant={variant}
      {...props}
    >
      {props.children}
    </StyledMaterialButton>
  );
};

export default EtrdButton;
