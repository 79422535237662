enum ROUTES {
  ROOT = '/',
  LOGIN = '/login',
  REGISTRATION = '/registration',
  PICK_PLAN = '/registration?select-plan=true',
  OFFER = '/offer',
  SEARCH = '/search',
  ADD_NEW_LISTING = '/add-new-listing',
  TRADER = '/trader',
  FORGOT_PASSWORD = '/forgot-password',
  CHANGE_PASSWORD = '/change-password',
  SETTINGS = '/settings',
  SETTINGS_PROFILE = '/settings/profile',
  SETTINGS_COMPANY = '/settings/company',
  SETTINGS_PASSWORD = '/settings/password',
  SETTINGS_PAYMENTS = '/settings/payments',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  NOT_FOUND = '/404',
  ERROR = '/500',
  MY_OFFERS = '/my-offers',
  EDIT_OFFER = '/edit-offer',
  FEEDBACK = '/feedback',
  CATEGORIES = '/categories',
  MATCHED_OFFERS = '/matched-offers',
}

export const AUTH_ROOTES = [
  ROUTES.LOGIN,
  ROUTES.REGISTRATION,
  ROUTES.PICK_PLAN,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.NOT_FOUND,
  ROUTES.ERROR,
];

export const PRIVATE_ROUTES_AVAILABLE_FOR_GUEST = [
  ROUTES.FEEDBACK,
  ROUTES.TERMS_AND_CONDITIONS,
];

export default ROUTES;
