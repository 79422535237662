import React from 'react';
import {
  Drawer,
  DrawerProps,
  SwipeableDrawer,
  SwipeableDrawerProps,
} from '@material-ui/core';

export const EtrdDrawer = (props: DrawerProps) => {
  return <Drawer {...props}>{props.children}</Drawer>;
};

export const EtrdSwappableDrawer = (props: SwipeableDrawerProps) => {
  return <SwipeableDrawer {...props}>{props.children}</SwipeableDrawer>;
};
