export const endpoints = {
  auth: {
    login: '/auth/login',
    refresh: '/auth/refresh',
    register: '/traders',
    confirmEmail: (uuid: string) => `/traders/${uuid}/confirm-email`,
    resetPassword: '/traders/any/reset-password',
    changePassword: (uuid: string) => `/traders/${uuid}/change-password`,
    metaData: '/traders/me',
    aggregatedData: '/traders/me/aggregated',
    subscriptions: '/traders/me/subscriptions',
  },
  trader: {
    getTraderAggregatedData: (uuid: string) => `/traders/${uuid}/aggregated`,
    sendMessageToTrader: (uuid: string) => `/traders/${uuid}/email`,
    addProfileCompanyImage: '/traders/me/company/logo',
    myProfile: '/traders/me/profile',
    myCompany: '/traders/me/company',
    updateTraderPassword: '/traders/me/update-password',
    recommendations: (uuid: string) => `/traders/${uuid}/recommendations`,
  },
  offer: {
    offers: '/offers',
    myOffers: '/traders/me/offers',
    uploadOfferImages: '/traders/me/offers/images',
    singleOffer: (id: number) => `/offers/${id}`,
    offerRecommendations: (id: number) => `/offers/${id}/recommendations`,
    mySingleOffer: (id: number) => `/traders/me/offers/${id}`,
  },
  category: {
    categories: '/categories',
    singleCategory: (id: number) => `/categories/${id}`,
  },
  marketplace: {
    myMarketplace: '/marketplaces/me',
    marketplaceAnnouncements: '/marketplaces/me/announcements',
  },
  utils: {
    search: '/search',
    countries: '/countries',
    subscriptions: '/subscriptions',
    messagingApps: '/messaging-apps',
    preferredContacts: '/preferred-contacts',
    termsAndConditions: 'documents/terms-and-conditions',
  },
  payments: {
    products: '/payments/products',
    prices: '/payments/me/subscriptions',
    portalSession: '/payments/me/portal-session',
  },
};
