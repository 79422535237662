import { categoriesActionsNames } from '@/store/modules/category/categoryActions';

export const categoriesReducer = (state = [], action): any => {
  switch (action.type) {
    case categoriesActionsNames.SET_CATEGORIES:
      return [...action.categories];
    default:
      return state;
  }
};
