import { Container, ContainerProps } from '@material-ui/core';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  &.MuiContainer-maxWidthLg {
    max-width: 1200px;
  }
`;

const EtrdContainer = (props: ContainerProps) => {
  return <StyledContainer {...props}>{props.children}</StyledContainer>;
};

export default EtrdContainer;
