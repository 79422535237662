import { clientApi, ssrApi } from '@/repository/serviceContainer';

export const countrirsActionsNames = {
  GET_COUNTRIES: 'getCountries',
};

export const countrirsActions = {
  getCountries: async (dispatch, ctx?) => {
    const client = ctx ? ssrApi : clientApi
    const countires = await client(ctx).utilsAppDataRepository.getCountries();
    dispatch({ type: countrirsActionsNames.GET_COUNTRIES, data: countires });
  },
};
