import { AxiosInstance, AxiosResponse } from 'axios';
import { endpoints } from '@/repository/endpoints';

export default class CategoriesRepository {
  constructor(private axiosInstance: AxiosInstance) {}
  getCategories(): Promise<AxiosResponse<any>> {
    return this.axiosInstance.get(endpoints.category.categories);
  }
  getCategory(id: number): Promise<AxiosResponse<any>> {
    return this.axiosInstance.get(endpoints.category.singleCategory(id), {
      params: {
        subcategories: true,
        attributes: true,
      },
    });
  }
}
