import {
  List,
  ListItem,
  ListItemIcon,
  ListItemIconProps,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemSecondaryActionProps,
  ListItemText,
  ListItemTextProps,
  ListProps,
} from '@material-ui/core';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const EtrdList = (props: ListProps & { hover?: boolean }) => {
  return <List {...props}>{props.children}</List>;
};

const StyledListItem = styled(ListItem)`
  &.Mui-disabled {
    pointer-events: none;

    * {
      pointer-events: none;
    }
  }
  &:hover {
    background: ${(props) => props.theme.palette.primary.light};
  }
  &.MuiListItem-root {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
  }
`;

export const EtrdListItem = (props: ListItemProps) => {
  // @ts-ignore
  return <StyledListItem {...props}>{props.children}</StyledListItem>;
};

interface EtrdListItemIconProps extends ListItemIconProps {
  children: ReactNode;
  color?: string;
}

const StyledEtrdListItemIcon = styled(ListItemIcon)`
  &&& svg {
    fill: ${(props) => props.color};
  }
`;

export const EtrdListItemIcon = (props: EtrdListItemIconProps) => {
  const { children } = props;
  return <StyledEtrdListItemIcon {...props}>{children}</StyledEtrdListItemIcon>;
};

export const EtrdListItemText = (props: ListItemTextProps) => {
  return <ListItemText {...props}>{props.children}</ListItemText>;
};

export const EtrdListItemSecondaryAction = (
  props: ListItemSecondaryActionProps
) => {
  return (
    <ListItemSecondaryAction {...props}>
      {props.children}
    </ListItemSecondaryAction>
  );
};
