import React from 'react';
import EtrdTooltip from '@/ui-kit/EtrdTooltip';
import EtrdIconButton from '@/ui-kit/EtrdIconButton';
import { AddCircle } from '@material-ui/icons';
import EtrdButton from '@/ui-kit/EtrdButton';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'next-i18next';
import { Search } from '@/layouts/BasicLayout/AppHeader/Search';
import ROUTES from 'constants/routes';
import EtrdLink from '@/ui-kit/EtrdLink';
import { useSelector } from 'react-redux';
import { IRootState } from '@/store/createStore';
import EtrdBox from '@/ui-kit/EtrdBox';
import { UserDropdownMenu } from '@/layouts/BasicLayout/AppHeader/UserDropdownMenu';
import EtrdHidden from '@/ui-kit/EtrdHidden';
import { useMediaQuery, useTheme } from '@material-ui/core';

export const MobileNewListingButton = () => {
  const { t } = useTranslation('common');
  return (
    <EtrdTooltip title={t('newListing')}>
      <EtrdIconButton>
        <EtrdLink href={ROUTES.ADD_NEW_LISTING} shallow={false}>
          <AddCircle color={'primary'} />
        </EtrdLink>
      </EtrdIconButton>
    </EtrdTooltip>
  );
};

export const RightActions = () => {
  const { t } = useTranslation('common');
  const profile = useSelector(
    (state: IRootState) => state.traderAuth.userProfile
  );
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const DesktopNewListingButton = () => (
    <EtrdLink href={ROUTES.ADD_NEW_LISTING} shallow={false}>
      <EtrdButton
        data-test="add-new-listing-button"
        endIcon={<AddIcon />}
        mr={3}
      >
        {t('newListing')}
      </EtrdButton>
    </EtrdLink>
  );

  const UnauthorizedUserButtons = () => (
    <EtrdHidden mdDown>
      <EtrdBox display="flex" alignItems="center">
        <EtrdLink href={ROUTES.REGISTRATION}>
          <EtrdButton mr={1} variant={'outlined'}>
            {t('register')}
          </EtrdButton>
        </EtrdLink>
        <EtrdLink href={ROUTES.LOGIN}>
          <EtrdButton>{t('login')}</EtrdButton>
        </EtrdLink>
      </EtrdBox>
    </EtrdHidden>
  );

  const LoggedUserButtons = () => {
    return (
      <>
        <EtrdHidden lgUp>
          <MobileNewListingButton />
        </EtrdHidden>
        <EtrdHidden mdDown>
          <DesktopNewListingButton />
        </EtrdHidden>
        <UserDropdownMenu />
      </>
    );
  };

  return (
    <>
      <Search />
      <EtrdBox display="flex" alignItems="center" justifyContent="center">
        {profile ? <LoggedUserButtons /> : <UnauthorizedUserButtons />}
      </EtrdBox>
    </>
  );
};
