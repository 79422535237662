import { Color } from '@material-ui/lab';

export const notificationActionsNames = {
  TOGGLE: 'toggle',
};

interface TogglePayload {
  message: string;
  type: Color;
}

export const notificationActions = {
  toggle: (value: TogglePayload, dispatch) => {
    dispatch({
      type: notificationActionsNames.TOGGLE,
      data: { ...value, visible: true },
    });
  },
};
