import { dispatchNestedResponse } from '@/shared/utils/dispatchNestedResponse';
import { logError } from '@/shared/utils/logger';
import { offersActionsNames } from '@/store/modules/offers/offersActions';

export const categoriesActionsNames = {
  GET_CATEGORIES: 'getCategories',
  SET_CATEGORIES: 'setCategories',
};

export const categoriesActions = {
  setCategories: (offers, dispatch) => {
    dispatch({ type: offersActionsNames.SET_OFFERS, offers });
  },
  getCategories: async (serviceContainer) => {
    try {
      const categories =
        await serviceContainer.categoriesRepository.getCategories();
      return dispatchNestedResponse(categories);
    } catch (e) {
      logError(e);
    }
  },
  // getAndSetCategories: async (dispatch) => {
  //   const categories = await categoriesActions.getCategories(serviceContainer);
  //   dispatch({ type: categoriesActionsNames.SET_CATEGORIES, categories });
  // },
};
