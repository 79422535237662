import { clientApi } from '@/repository/serviceContainer';
import { applyParamsToUrlAndRedirect } from '@/shared/utils/applyParamsToUrl';
import { offersFiltersActions } from '@/store/modules/offerFilters/offersFiltersActions';
export const offersActionsNames = {
  SET_OFFERS: 'setOffers',
};

export const offersActions = {
  setOffers: (offers, dispatch) => {
    dispatch({ type: offersActionsNames.SET_OFFERS, offers });
  },

  getOffers: async (dispatch, store) => {
    const state = store.getState();
    const { offersFilters } = state;
    const { data: offers, meta } = await clientApi().offerRepository.getOffers(
      offersFilters
    );
    const newFilters = { ...offersFilters, ...meta };
    offersFiltersActions.setFilter(newFilters, dispatch);
    offersActions.setOffers(offers, dispatch);
    await applyParamsToUrlAndRedirect(newFilters);
  },

  setFilterAndGetOffers: async (filter, dispatch, store) => {
    offersFiltersActions.handleAddFilter(filter, dispatch);
    await offersActions.getOffers(dispatch, store);
  },

  resetFilterAndGetOffers: async (category, dispatch, store) => {
    offersFiltersActions.resetFilters(category, dispatch);
    await offersActions.getOffers(dispatch, store);
  },
};
