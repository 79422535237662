import { LatestOffer } from '@/repository/offerRepository/OfferRepository';

export const offerListAdapter = (offers: any): LatestOffer[] => {
  // TODO fix any to type from backend (when backed will be improved)
  return offers.map((offer) => ({
    createdAt: offer.createdAt,
    title: offer.title,
    price: offer.price,
    category: offer.category,
    slug: offer.slug,
  }));
};
