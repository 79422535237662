export const removeEmptyProperties = (obj: any) => {
  const copy = { ...obj };
  const keys = Object.keys(obj);

  for (let i = 0; i < keys.length; i++) {
    if (obj[keys[i]] === undefined || obj[keys[i]] === '') {
      copy[keys[i]] = null;
    }
  }

  return copy;
};
