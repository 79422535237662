import {
  searchTree,
  getCategoryIdFromSlug,
  getCurrentCategoryWithRoot,
} from '@/utils/categories';

export const getCategoryBySlug = (slug, categories) => {
  for (let i = 0; i < categories.length; i++) {
    const currentCategory = searchTree(
      categories[i],
      getCategoryIdFromSlug(slug)
    );
    if (currentCategory) {
      return currentCategory;
    }
  }
};
export const getCategoryById = (id, categories) => {
  for (let i = 0; i < categories.length; i++) {
    const currentCategory = searchTree(categories[i], id);
    if (currentCategory) {
      return currentCategory;
    }
  }
};
export const getBreadcrumbsCategories = (slug, categories) => {
  const categoryData = getCurrentCategoryWithRoot(slug, categories);
  if (!categoryData) {
    return null;
  }
  const { rootCategory, currentCategory } = categoryData;
  const breadcrumbs = [];

  breadcrumbs.push(currentCategory);

  let loopCategory = { ...currentCategory };
  while (loopCategory.parentId) {
    const parentCategory = searchTree(rootCategory, loopCategory.parentId);
    loopCategory = parentCategory;
    breadcrumbs.push(parentCategory);
  }

  return breadcrumbs.reverse();
};

export const getSiblings = (slug, categories) => {
  const categoryData = getCurrentCategoryWithRoot(slug, categories);
  if (!categoryData) {
    return null;
  }
  const { rootCategory, currentCategory } = categoryData;
  const parent = searchTree(rootCategory, currentCategory.parentId);
  return parent.subcategories;
};

export const getCategoryFromPath = (path) => {
  return path.substring(path.lastIndexOf('/') + 1, path.length);
};

export const getNavBarCategories = (categories) =>
  categories
    ?.filter((category) => category.showInNavBar)
    .sort((a, b) => (a.order > b.order ? 1 : -1));
