import React from 'react';
import styled from 'styled-components';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import EtrdBox from '@/ui-kit/EtrdBox';
import EtrdGrid from '@/ui-kit/EtrdGrid';
import EtrdButton from '@/ui-kit/EtrdButton';
import { useTranslation } from 'next-i18next';
import ROUTES from '@/shared/constants/routes';
import EtrdLink from '@/ui-kit/EtrdLink';

const LetsSignInWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  overflow: hidden;
  position: relative;
  margin-bottom: 3rem;
  background-color: ${(props) => props.theme.palette.secondary.main};

  .MuiTypography-root {
    color: ${(props) => props.theme.palette.primary.light};
  }
`;

export const LetsSignIn = () => {
  const { t } = useTranslation('landingPage');
  return (
    <LetsSignInWrapper>
      <EtrdGrid container>
        <EtrdGrid item md={12} lg={7}>
          <EtrdBox>
            <EtrdTypography mb={2} variant="h5">
              {t('letsSignInTitle')}
            </EtrdTypography>
            <EtrdTypography display="block" mb={2} variant="caption">
              {t('letsSignInSubtitle')}
            </EtrdTypography>
          </EtrdBox>
        </EtrdGrid>
        <EtrdGrid item md={12} lg={5}>
          <EtrdBox
            style={{ height: '100%' }}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <EtrdLink href={ROUTES.REGISTRATION} shallow={false}>
              <EtrdButton size={'large'}>{t('becomeMember')}</EtrdButton>
            </EtrdLink>
          </EtrdBox>
        </EtrdGrid>
      </EtrdGrid>
    </LetsSignInWrapper>
  );
};
