import { MAIN_KEY } from '@/utils/tree/TreeNode';

export const getCurrentCategoryWithRoot = (
  slug: string,
  categories: any,
  id?: number
) => {
  for (let i = 0; i < categories.length; i++) {
    const currentCategory = searchTree(
      categories[i],
      id ? id : getCategoryIdFromSlug(slug)
    );
    if (currentCategory) {
      return {
        rootCategory: categories[i],
        currentCategory,
      };
    }
  }
  return null;
};
export const searchTree = (element: any, id: number): any => {
  if (element.id === id) {
    return element;
  }
  if (element.subcategories != null) {
    let result = null;
    for (let i = 0; result == null && i < element.subcategories.length; i++) {
      result = searchTree(element.subcategories[i], id);
    }
    return result;
  }
  return null;
};

export const getCategoryIdFromSlug = (slug: string) =>
  Number(slug.substring(0, slug.indexOf('-')));

export const getCategoryById = (id: string, categories: any) => {
  for (let i = 0; i < categories.length; i++) {
    const result = searchTree(categories[i], Number(id));
    if (result) {
      return result;
    }
  }
  return null;
};

export function getLeafNodes(nodes: any[], result: any = []) {
  for (let i = 0, length = nodes.length; i < length; i++) {
    if (!nodes[i].subcategories || nodes[i].subcategories.length === 0) {
      result.push(nodes[i]);
    } else {
      result = getLeafNodes(nodes[i].subcategories, result);
    }
  }
  return result;
}
