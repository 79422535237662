import { useTranslation } from 'next-i18next';
import { EtrdCard, EtrdCardHeader } from '@/ui-kit/EtrdCard';
import Carousel from 'react-material-ui-carousel';
import React from 'react';
import { Announcement } from '@/components/LandingPage/Announcement';

export const AnnouncementsBoard = ({ announcements }) => {
  const { t } = useTranslation('landingPage');
  if (announcements.length === 0) {
    return null;
  }
  return (
    <EtrdCard mt={4} pb={3} style={{ overflow: 'visible' }}>
      <EtrdCardHeader title={t('announcements')} />
      <Carousel navButtonsAlwaysInvisible interval={5000}>
        {announcements.map((announcement) => (
          <Announcement announcement={announcement} key={announcement.id} />
        ))}
      </Carousel>
    </EtrdCard>
  );
};
