import { AxiosInstance } from 'axios';
import { endpoints } from '@/repository/endpoints';
import { SearchResponseDto } from '@/shared/types/definitions/search';

class SearchRepository {
  constructor(private axiosInstance: AxiosInstance) {}

  async search(query): Promise<SearchResponseDto> {
    const response = await this.axiosInstance.get(endpoints.utils.search, {
      params: { q: query },
    });
    return response.data.data;
  }
}

export default SearchRepository;
