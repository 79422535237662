import React from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';
import styled from 'styled-components';
import { SpaceProps, space } from 'styled-system';
import { makeStyles } from '@material-ui/core/styles';

const StyledTooltip = styled(Tooltip)`
  ${space}
`;

const useStyles = makeStyles((theme) => ({
  popper: {
    color: theme.palette.primary.light,
    background: theme.palette.primary.dark,
  },
  arrow: {
    color: theme.palette.primary.dark,
  },
}));

export type EtrdTooltipProps = TooltipProps & SpaceProps;

const EtrdTooltip = (props: EtrdTooltipProps) => {
  const classes = useStyles();
  return (
    <StyledTooltip
      arrow
      classes={{
        tooltip: classes.popper,
        arrow: classes.arrow,
      }}
      {...props}
    >
      {props.children}
    </StyledTooltip>
  );
};

export default EtrdTooltip;
