import { clientApi } from '@/repository/serviceContainer';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { traderAuthActions } from '@/store/modules/auth/traderAuth/traderAuthActions';
import { useRouter } from 'next/router';
import ROUTES from 'constants/routes';
import { authTokens, getToken } from '@/shared/utils/token';
import { notificationActions } from '@/store/modules/notification/notificationActions';
import { useTranslation } from 'next-i18next';
import { logError } from '@/shared/utils/logger';
import { IRootState } from '@/store/createStore';
import { activeSubscriptionIsPresent } from '@/shared/utils/activeSubscriptionIsPresent';
export interface LoginPayload {
  username: string;
  password: string;
}

export default function useLogin() {
  const { t } = useTranslation(['common', 'login']);
  const cookies = new Cookies();
  const [errorsWithLogin, setErrorsWithLogin] = useState(null);
  const dispatch = useDispatch();
  const router = useRouter();
  const marketplace = useSelector((state: IRootState) => state.marketplace);

  const redirectUser = async (userIsActive: boolean) => {
    if (!userIsActive && marketplace.hasSubscriptions) {
      // REDIRECT USER WHO DOESN'T HAVE PICKED SUBSCRIPTION
      return await router.push(ROUTES.PICK_PLAN, undefined, { shallow: false });
    }
    return await router.push(ROUTES.ROOT, undefined, { shallow: false });
  };

  const authorize = async (loginPayload: LoginPayload) => {
    const { username, password } = loginPayload;
    cookies.remove(authTokens.Token, { path: ROUTES.ROOT });
    cookies.remove(authTokens.RefreshToken, { path: ROUTES.ROOT });
    const { accessToken, refreshToken } =
      await clientApi().authRepository.loginTrader(username, password);

    cookies.set(authTokens.Token, accessToken, { path: ROUTES.ROOT });
    cookies.set(authTokens.RefreshToken, refreshToken, {
      path: ROUTES.ROOT,
      encode: (v) => v,
    }); // encode to avoid parsing : to ?
  };

  const handleLogout = async () => {
    cookies.remove(authTokens.Token, { path: ROUTES.ROOT });
    cookies.remove(authTokens.RefreshToken, { path: ROUTES.ROOT });
    traderAuthActions.logout(dispatch);
    notificationActions.toggle(
      {
        message: t('logoutSuccess'),
        type: 'success',
      },
      dispatch
    );
    return await router.push(ROUTES.LOGIN);
  };

  const handleLogin = async (loginPayload: LoginPayload) => {
    setErrorsWithLogin('');
    try {
      await authorize(loginPayload);

      const userMetaData = await clientApi().authRepository.getMetaData();
      const userProfile = await clientApi().authRepository.getUserProfile();
      traderAuthActions.setUserMetaData(userMetaData, dispatch);

      const userIsActive = await activeSubscriptionIsPresent(
        undefined,
        userProfile.data.data.trialPeriodEndsAt
      );

      if (userIsActive) {
        const userProfile = await clientApi().authRepository.getUserProfile();
        traderAuthActions.setUserProfile(userProfile.data.data, dispatch);
      }

      if (router.query?.returnUrl) {
        await router.push(router.query.returnUrl as string);
      } else {
        return await redirectUser(userIsActive);
      }
    } catch (error) {
      logError(error);
      const { errorCode } = error.response.data;
      switch (errorCode) {
        case 'INVALID_USERNAME_OR_PASSWORD_EXCEPTION': {
          setErrorsWithLogin(t('login:incorrectCredentialsMessage'));
          break;
        }
        case 'EMAIL_NOT_CONFIRMED_EXCEPTION': {
          setErrorsWithLogin(t('login:emailNotConfirmed'));
          break;
        }
        case 'LOGIN_FORBIDDEN_EXCEPTION': {
          // case the account has not been accepted / the account has been blocked
          setErrorsWithLogin(t('login:accountNeedToBeConfirmedByAdmin'));
          break;
        }
        default: {
          setErrorsWithLogin(t('common:commonApiError'));
        }
      }
    }
  };

  const isAuthorized = () => getToken();

  return {
    handleLogin,
    errorsWithLogin,
    handleLogout,
    isAuthorized,
  };
}
