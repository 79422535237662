import React, { Fragment } from 'react';
import { format } from 'date-fns';
import { EtrdCard, EtrdCardActions, EtrdCardHeader } from '@/ui-kit/EtrdCard';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import EtrdButton from '@/ui-kit/EtrdButton';
import { LatestOffer } from '@/repository/offerRepository/OfferRepository';
import ROUTES from '@/shared/constants/routes';
import { EtrdList, EtrdListItem } from '@/ui-kit/EtrdList/EtrdList';
import EtrdGrid from '@/ui-kit/EtrdGrid';
import EtrdDivider from '@/ui-kit/EtrdDivider';
import { useMediaQuery, useTheme, withWidth } from '@material-ui/core';
import { Price } from '@/components/Price/Price';
import { OfferChip } from '@/shared-components/OfferChip/OfferChip';
import EtrdBox from '@/ui-kit/EtrdBox';
import styled from 'styled-components';
import { OFFER_TYPE } from '@/utils/models/offer';
import EtrdLink from '@/../../libs/ui-kit/src/EtrdLink';
import EtrdAlert from '@/ui-kit/EtrdAlert';
import { useTranslation } from 'next-i18next';

interface EtrdOffersCardProps {
  offers: LatestOffer[];
  isAuthorized: boolean;
  title: string;
  buttonTitle: string;
  showOfferType?: boolean;
  type: OFFER_TYPE;
  uuid?: string;
}

const StyledEtrdGrid = styled(EtrdGrid)`
  .truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const EtrdOffersCard = (props: EtrdOffersCardProps) => {
  const {
    offers,
    title,
    buttonTitle,
    showOfferType = false,
    type,
    uuid,
  } = props;
  const theme = useTheme();
  const isXsmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const traderUuidParam = uuid ? `&trader=${uuid}` : '';
  const allOffersHref = `${ROUTES.SEARCH}?type=${type}${traderUuidParam}`;
  const noOffers = offers.length === 0;
  const { t } = useTranslation('offers');

  return (
    <EtrdCard>
      <EtrdCardHeader title={title} />

      <EtrdList>
        {noOffers && (
          <EtrdBox p={2}>
            <EtrdAlert severity="info">{t('noOffers')}</EtrdAlert>
          </EtrdBox>
        )}

        {!noOffers &&
          offers.map((offer, idx: number) => (
            <EtrdLink key={idx} href={`${ROUTES.OFFER}/${offer.slug}`}>
              <Fragment>
                <EtrdDivider />
                <EtrdListItem
                  style={{ height: '4rem', borderRadius: 0 }}
                  key={idx}
                  button
                >
                  <StyledEtrdGrid container>
                    <StyledEtrdGrid
                      container
                      justifyContent={'space-between'}
                      xs={12}
                    >
                      <EtrdGrid
                        item={true}
                        style={{ overflow: 'hidden' }}
                        container
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                        xs
                      >
                        <EtrdBox
                          display="flex"
                          alignItems="center"
                          overflow={'hidden'}
                        >
                          {showOfferType ? (
                            <OfferChip type={offer.type} />
                          ) : null}

                          <EtrdTypography
                            className="truncated"
                            ml={showOfferType ? 2 : 0}
                          >
                            {offer.title}
                          </EtrdTypography>
                        </EtrdBox>
                      </EtrdGrid>

                      <EtrdGrid
                        item={true}
                        xs={3}
                        md={2}
                        container
                        justifyContent={'flex-end'}
                      >
                        <EtrdTypography
                          align={'left'}
                          weight={'bold'}
                          className="truncated"
                        >
                          <Price price={offer.price} />
                        </EtrdTypography>
                      </EtrdGrid>
                    </StyledEtrdGrid>

                    <StyledEtrdGrid
                      container
                      justifyContent={'space-between'}
                      xs={12}
                    >
                      <EtrdGrid item={true} xs>
                        <EtrdTypography
                          color={'textSecondary'}
                          variant={'caption'}
                          ml={isXsmallScreen ? 0 : 1}
                        >
                          {format(new Date(offer.createdAt), 'yyyy-MM-dd')}
                        </EtrdTypography>
                      </EtrdGrid>

                      <EtrdGrid
                        item={true}
                        xs
                        container
                        justifyContent={'flex-end'}
                      >
                        <EtrdTypography
                          className="truncated"
                          variant={'caption'}
                          align="right"
                          color={'textSecondary'}
                        >
                          {offer.category.name}
                        </EtrdTypography>
                      </EtrdGrid>
                    </StyledEtrdGrid>
                  </StyledEtrdGrid>
                </EtrdListItem>
              </Fragment>
            </EtrdLink>
          ))}
      </EtrdList>

      <EtrdCardActions filled="true">
        <EtrdLink href={allOffersHref}>
          <EtrdButton fullWidth>{buttonTitle}</EtrdButton>
        </EtrdLink>
      </EtrdCardActions>
    </EtrdCard>
  );
};

export default withWidth()(EtrdOffersCard);
