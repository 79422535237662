import React from 'react';
import EtrdOffersCard from '@/components/LandingPage/EtrdOfferbox';
import EtrdGrid from '@/ui-kit/EtrdGrid';
import useLogin from '@/components/Login/hooks/useLogin';
import { useTranslation } from 'next-i18next';
import { OFFER_TYPE } from '@/utils/models/offer';

export const LatestOffers = ({ offers }) => {
  const { t } = useTranslation('home');
  const { isAuthorized } = useLogin();
  if (!offers) {
    return null;
  }

  return (
    <EtrdGrid
      container
      direction="row"
      justifyContent="center"
      mt={12.5}
      mb={4}
      spacing={2}
    >
      <EtrdGrid item lg={6} xs={12}>
        <EtrdOffersCard
          isAuthorized={isAuthorized()}
          title={t('latestSellOffers')}
          offers={offers.sellOffers}
          buttonTitle={t('allSellOffers')}
          type={OFFER_TYPE.SELL}
        />
      </EtrdGrid>
      <EtrdGrid item lg={6} xs={12}>
        <EtrdOffersCard
          isAuthorized={isAuthorized()}
          title={t('latestBuyOffers')}
          offers={offers.buyOffers}
          buttonTitle={t('allBuyOffers')}
          type={OFFER_TYPE.BUY}
        />
      </EtrdGrid>
    </EtrdGrid>
  );
};
