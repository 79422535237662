import { ImageType } from 'react-images-uploading/dist/typings';

import { TranslateFunction } from './misc';

export enum OFFER_TYPE {
  BUY = 'buy',
  SELL = 'sell',
  ALL = 'all',
}
export type OfferFilters = {
  page: number;
  limit: number;
  sort: string;
  q?: string;
} & Partial<any>;

export type SingleFilter = Partial<OfferFilters>;

export interface Offer {
  id: number;
  slug: string;
  traderUUID: string;
  title: string;
  keywords: string;
  description: string;
  price: number;
  hasPrice: boolean;
  isPriceNegotiable: boolean;
  isEnabled: boolean;
  quantity: number;
  type: 'sell' | 'buy';
  primaryCategoryId: number;
  categoryIds: Array<number>;
  images: string;
  customAttributes: Array<string>;
  createdAt: string;
  updatedAt: string;
  attributes: string;
  traderCompany: {
    companyName: string;
  };
  category: {
    name: string;
  };
}

interface OfferCategory {
  slug: string;
  id: number;
  name: string;
}

interface OfferTraderCompany {
  about: string;
  companyName: string;
  countryAlpha2: string;
  trustLevel: number;
}
export interface AttributeValue {
  id: number;
  value: string;
}
export interface OfferAttribute {
  id: number;
  isMultipleChoice: boolean;
  isRequired: boolean;
  key: string;
  values: AttributeValue[];
}

export interface OfferImage {
  tags: Array<string>;
  url: string;
}

export type OfferType = OFFER_TYPE.BUY | OFFER_TYPE.SELL;
export interface OfferRequestFilter {
  page?: number;
  limit?: number;
  category?: number;
  filter?: Array<string>;
  trader?: string;
  priceMin?: number;
  priceMax?: number;
  type?: OfferType;
  keywords?: string;
  attributes?: boolean;
}

interface OfferBase {
  id: number;
  slug: string;
  traderUUID: string;
  title: string;
  description: string;
  price: string;
  hasPrice: boolean;
  isPriceNegotiable: boolean;
  quantity: number;
  type: string;
  createdAt: string;
  updatedAt: string;
  isNetwork: boolean;
}

export type OfferWithCategoryAndTraderDto = {
  category: OfferCategory;
  traderCompany: OfferTraderCompany;
} & OfferBase;

export type OfferWithAttributes = {
  attributes: Array<OfferAttribute>;
  categoryIds: Array<number>;
  customAttributes: Array<OfferAttribute>;
  images: Array<OfferImage>;
  keywords: Array<string>;
  primaryCategoryId: number;
  [key: string]: any;
} & OfferBase;

export interface CreateOfferDto {
  title: string;
  keywords?: Array<string>;
  description: string;
  price: number;
  isPriceNegotiable: boolean;
  quantity: number;
  type: string;
  primaryCategoryId: number;
  categoryIds: Array<string>;
  isEnabled: boolean;
  attributeValues: Array<string>;
  customAttributes: Array<string>;
  images: Array<OfferImage>;
}
export interface OffersContainerRenderProps {
  handleSubmit: (data: any) => Promise<void>;
  handleAddImage: (images: ImageType[], index?: number[]) => Promise<void>;
  currencyCode: string;
  images: ImageType[];
  offer?: OfferWithAttributes;
  categoryAttributes: Array<OfferAttribute>;
  handleDeleteImage: (index: number) => void;
  category?: string;
  adminMode?: boolean;
  editMode?: boolean;
  isPriceRequired?: boolean;
  imagesChanged?: boolean;
}
export interface OffersTableContainerRenderProps {
  fetchOffers: (filters: OfferFilters) => void;
  offers: Offer[];
  filters: OfferFilters;
  addFilter: (filter: SingleFilter, shouldResetPage?: boolean) => Promise<void>;
  removeFilter: (key: keyof OfferFilters) => void;
  toggleOffer: (id: number, isEnabled: boolean) => void;
}
